import { store } from '../store'
import services from '../ws/services'
import { getCurrentInstance } from 'vue'
//import moment from "moment";

import { aesUtil } from '../aes'

export const myMixin = {

    data : {
      instance : null,
    },

    created(){
      this.instance = getCurrentInstance();
    },

    methods : {

      processLogin(myUser, myPwd) {

        //show loader:
        //this.dialogLoader = true;
        store.dialogLoader = true;

        const formData = new FormData();
        formData.append("grant_type", "password");
        formData.append("username", myUser);
        formData.append("password", myPwd);

        this.axios
          .post(services.serviceHost + "/oauth/token", formData, {
            auth: {
              username: "client",
              password: "123"
            }
          })
          .then(response => {
            //granvando token:
            store.currentToken = response.data.access_token;

            console.log('token: \n' + response.data.access_token);

            var storage = window.localStorage;
            storage.setItem('username', aesUtil.encryptString(services.myphrase, myUser));
            storage.setItem('password', aesUtil.encryptString(services.myphrase, myPwd));

            this.axios({
              method: "post",
              url: services.serviceHost + "/user/logged",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.currentToken}`
              },
              data: {}
            }).then(response2 => {
              store.userLogged = response2.data;
              store.dialogLoader = false;
              this.testLoggedUserAPI();
              
              //this.loadBasicGlobalData();
              console.log('roles: ' + store.userLogged.roleAdmin + ', ' + store.userLogged.rolePrestador);
              if(store.redirectPage){
                this.$router.push(store.redirectPage);
              }else{
                this.$router.push('/');
              }

            })
              .catch(error2 => {
                console.log(error2.message);
                this.cleanLocalUserData();
                store.loginErrorMessage = "Usuário e senha não conferem.";
                store.dialogLoader = false;
              });

          })
          .catch(error => {
            console.log(error.message);
            this.cleanLocalUserData();
            store.loginErrorMessage = "Usuário e senha não conferem.";
            store.dialogLoader = false;
          });
      },

      async checkEmailExists(emailToCheck) {
        store.dialogLoader = true;

        await this.axios({
          method: "post",
          url: services.serviceHost + "/user/checkemail",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.currentToken}`
          },
          params: {
            email: emailToCheck
          }
        }).then(response => {
          console.log('check email, existe: ' + response.status);
          store.dialogLoader = false;
          if (response.status == 204) {
            return false;
          } else if (response.status == 200) {
            return true;
          }
        })
          .catch(error => {
            console.log('check email, error : ' + error.status);
            store.dialogLoader = false;
            return true;
          });
      },

      testaCPF(strCPF) {
        var Soma;
        var Resto;
        Soma = 0;
        //strCPF  = RetiraCaracteresInvalidos(strCPF,11);
        if (strCPF == "00000000000")
          return false;
        for (var i = 1; i <= 9; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;
        if ((Resto == 10) || (Resto == 11))
          Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10)))
          return false;
        Soma = 0;
        for (i = 1; i <= 10; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
        if ((Resto == 10) || (Resto == 11))
          Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11)))
          return false;
        return true;
      },

      isLetter(e) {
        let char = String.fromCharCode(e.keyCode); // Get the character
        if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
        else e.preventDefault(); // If not match, don't add to input text
      },

      simpleServiceCall(){
  
        const config = {
            headers: { Authorization: `Bearer ${store.currentToken}` }
        };
        this.axios
            .get(services.serviceHost + "/user/healthcheck", {}, config)
            .then(response => {
            console.log(response.data);
        });
      },

      checkTk(){
  
        const config = {
            headers: { Authorization: `Bearer ${store.currentToken}` }
        };
        this.axios
            .get(services.serviceHost + "/user/checktk", {}, config)
            .then(response => {
            console.log('token válido : ' + response.data);
            return true;
        })
        .catch(error => {
          console.log('token inválido : ' + error);
          store.dialogLoader = false;
          store.userLogged = null;
          store.correntToken = null;
          return false;
        });
      },

      testLoggedUserAPI(){

        store.dialogLoader = true;
  
        this.axios({
          method: "post",
          url: services.serviceHost + "/user/check-user-binance-api",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.currentToken}`
          },
        })
        .then(response => {
          console.log('api valid: ' + response.data);
          store.isCurrentAPIvalid = response.data;
          store.dialogLoader = false;
        });
  
      },  

      cleanLocalUserData(){

        var storage = window.localStorage;
        storage.setItem('username', null);
        storage.setItem('password', null);
      },
      
      softLogout(){

        store.userLogged = null;
        store.correntToken = null;

        this.$router.push("/");
  
        this.axios({
          method: "post",
          url: services.serviceHost + "/oauth/logout",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.currentToken}`
          },
          data: {}
        }).then(response => {
          console.log('logout: ' + response.status);
          store.dialogLoader = false;
          store.userLogged = null;
          store.correntToken = null;

        })
        .catch(error => {
          console.log('token inválido : ' + error);
          store.dialogLoader = false;
          store.userLogged = null;
          store.correntToken = null;
        });
  
      },

      logout(){

        store.userLogged = null;
        store.correntToken = null;

        this.$router.push("/");
  
        this.axios({
          method: "post",
          url: services.serviceHost + "/oauth/logout",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.currentToken}`
          },
          data: {}
        }).then(response => {
          console.log('logout: ' + response.status);
          store.dialogLoader = false;
          store.userLogged = null;
          store.correntToken = null;
          this.cleanLocalUserData();
          this.$router.push("/");

        })
        .catch(error => {
          console.log('token inválido : ' + error);
          store.dialogLoader = false;
          store.userLogged = null;
          store.correntToken = null;
          this.cleanLocalUserData();
          this.$router.push("/");
        });
  
      },

      reloadUserData () {

        this.axios({
          method: "post",
          url: services.serviceHost + "/user/logged",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.currentToken}`
          },
        })
        .then(response => {
          store.userLogged = response.data;
          store.dialogLoader = false;
        });
      },

      truncateToScale(num, scale) {
        if (typeof num !== 'number' || typeof scale !== 'number') {
            throw new Error("Both num and scale must be numbers");
        }
        const scaleFactor = Math.pow(10, scale);
        return Math.trunc(num * scaleFactor) / scaleFactor;
      },
      
      formataMoedaBRL(valor){
          if(valor != null && valor != ''){
            return valor.toLocaleString('pt-br', {minimumFractionDigits: 2});
          }
          else{
            return new Number(0).toLocaleString('pt-br', {minimumFractionDigits: 2});
          }
      },

      formataMoedaUSD(valor){
        if(valor != null && valor != ''){
          return valor.toLocaleString('en-us', {minimumFractionDigits: 2});
        }
      },

      formatDecimalToUSD(valor, digits){
        if(valor != null && valor != ''){
          return valor.toLocaleString('en-us', {minimumFractionDigits: digits});
        }
        let zero = 0;
        return zero.toLocaleString('en-us', {minimumFractionDigits: digits});
      },

      formatDecimalToBRL(valor, digits){
        if(valor != null && valor != ''){
          return valor.toLocaleString('pt-br', {minimumFractionDigits: digits});
        }
      },

      buildNameForURL(myName){
        if(myName){
          return myName
            .normalize()
            .replaceAll(' > ', '/')
            .replaceAll(' ', '_')
            .replaceAll('.', '')
            .replaceAll(',', '')
            .replaceAll(':', '_')
            .replaceAll(')', '')
            .replaceAll('(', '')
            .replaceAll('"', '')
            .replaceAll('-', '')
            .toLowerCase()
        }
        return myName;
      },

      capitalizeString(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

      getHoraAgora(){
        return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5);
      },

      getHoje(){
        return this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10));
      },

      somaDias(myDate, diasParaSomar){

        myDate.setDate(myDate.toDate().getDate() + diasParaSomar);
        return myDate;
      },

      calculaIdade(dataNasc){ 
        
        if(dataNasc && dataNasc.indexOf('/') != -1){
          var dataAtual = new Date();
          var anoAtual = dataAtual.getFullYear();
          var anoNascParts = dataNasc.split('/');
          var diaNasc =anoNascParts[0];
          var mesNasc =anoNascParts[1];
          var anoNasc =anoNascParts[2];
          var idade = anoAtual - anoNasc;
          var mesAtual = dataAtual.getMonth() + 1; 
          //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
          if(mesAtual < mesNasc){
            idade--; 
          } else {
          //Se estiver no mes do nascimento, verificar o dia
            if(mesAtual == mesNasc){ 
              if(new Date().getDate() < diaNasc ){ 
              //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
              idade--; 
              }
            }
          } 
          return idade; 
        }else{
          return 0;
        }
      },

      getRolesAsList(myUser){
        let result = '';
        if(myUser && myUser.roles){
          for(var i = 0; i < myUser.roles.length; i++){
            result += myUser.roles[i].simpleName + ', '
          }
          return result.substring(0, result.length - 2);
        }
        return result;
      },

      abrePagina(myPage){
        window.open(myPage, '_blank');
      },

      reverseString(str) {
        return str.split('').reduce((reversedStr, char) => char + reversedStr, '');
      }
      
      

    },

    
}