export default {
    
    //serviceHost: 'http://localhost:8091',
    serviceHost : 'https://binance-robot-backend-f35920957d0e.herokuapp.com',
    
    //setup manager micro service host:
    apiKeySetupManager : 'NvXQ7guUWrKgdQQ6i1EfZwSFsYGZWoUeWUJ0JTxlkaBIy3BkFWedHcPalYSi8dFH',
    serviceSetupManager : 'http://localhost:8091',

    myphrase : 'XSVQb1G0PJJm5Xv',
    myAppName : 'Tourmalet',
}   