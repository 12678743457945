import { createRouter, createWebHashHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import LoginPage from '../views/LoginPage.vue'
import ApiManager from '../views/ApiManager.vue'
import RobotConfig from '../views/RobotConfig.vue'
import RemoveAccount from '../views/RemoveAccount.vue'
import MySubscriptions from '../views/MySubscriptions.vue'

import NewAccount from '../views/account/NewAccount.vue'

//páginas do setup:
//import MyCustomSetups from '../views/setup/MyCustomSetups.vue'

import MyTransactions from '../views/MyTransactions.vue'
import ResultsAndProfits from '@/views/ResultsAndProfits.vue'

//páginas não logadas:
import FaqPage from '../views/FaqPage.vue'
import SupportPage from '../views/SupportPage.vue'
import RecoverPassword from '../views/RecoverPassword.vue'
import PrivacyTerms from '../views/PrivacyTerms.vue'

//admin pages:
import MyGlobalConfiguration from '../views/admin/MyGlobalConfiguration'
import SetupResults from '../views/admin/SetupResults'
import ManageCoins from '@/views/admin/ManageCoins.vue'

import { store } from '../store';

import services from '@/ws/services';
import axios from 'axios';

const router = createRouter({

  //history: createWebHashHistory('/'),
  history: createWebHashHistory(),
  routes: [
    
    //páginas não logadas
    {
        path: '/login',
        name: 'loginPage',
        component: LoginPage
    },
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
        path: '/faqPage',
        name: 'faqPage',
        component: FaqPage
    },
    {
        path: '/supportPage',
        name: 'supportPage',
        component: SupportPage
    },
    {
        path: '/account/new',
        name: 'newAccount',
        component: NewAccount
    },
    {
        path: '/recoverPassword',
        name: 'recoverPassword',
        component: RecoverPassword
    },
    {
      path: '/privacyTerms',
      name: 'privacyTerms',
      component: PrivacyTerms
    },
    

    //páginas do user logado:
    {
        path: '/myTransactions',
        name: 'myTransactions',
        component: MyTransactions
    },
    {
        path: '/apiManager',
        name: 'apiManager',
        component: ApiManager
    },
    {
        path: '/robotConfig',
        name: 'robotConfig',
        component: RobotConfig
    },
    {
        path: '/removeAccount',
        name: 'removeAccount',
        component: RemoveAccount
    },
    {
      path: '/mySubscriptions',
      name: 'mySubscriptions',
      component: MySubscriptions
    },
    {
      path: '/resultsAndProfits',
      name: 'resultsAndProfits',
      component: ResultsAndProfits
  },


    //admin pages
    {
      path: '/myGlobalConfiguration',
      name: 'myGlobalConfiguration',
      component: MyGlobalConfiguration
    },
    {
      path: '/setupResults',
      name: 'setupResults',
      component: SetupResults
    },
    {
      path: '/manageCoins',
      name: 'manageCoins',
      component: ManageCoins
    }
    

  ]
})

router.beforeEach(async (to, from) => {
  console.log(from.fullPath + ' -> ' + to.fullPath);
  console.log(from.name + ' -> ' + to.name);

  if(
    to.name !== 'loginPage' &&
    to.name !== 'home' &&
    to.name !== 'faqPage' &&
    to.name !== 'supportPage' && 
    to.name !== 'recoverPassword' && 
    to.name !== 'privacyTerms' &&
    to.name !== 'newAccount'
    // to.name !== 'novaConta' && 
    // to.name !== 'recover'&&
    // to.name !== 'userInvite'
  ){
    const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    axios
      .post(services.serviceHost + "/user/checktk", {}, config)
      .then(response => {
      console.log('token válido : ' + response.status);
    })
    .catch(error => {
      console.log('token inválido : ' + error.status);
      store.dialogLoader = false;
      store.userLogged = null;
      store.correntToken = null;

      return { name: 'home' }
    });
  }else{
    console.log('no login needed');
  }
  
})

export default router
