<template>

  <div>
    <UserLoggedDashboard v-if="store.userLogged" />
    <WelcomeHome v-if="!store.userLogged" />

    </div>

</template>

<script>

import { myMixin } from '../mixin';
import services from '../ws/services';
import { store } from '../store';

import UserLoggedDashboard from '../components/UserLoggedDashboard';
import WelcomeHome from '../components/WelcomeHome';

export default {

  mixins: [
    myMixin,
  ],

  components :{
    UserLoggedDashboard,
    WelcomeHome,
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : false,

  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    //this.simpleServiceCall();
    //this.loadBasicGlobalData();
  },

  methods: {

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    },

  }

}

</script>

<style scoped>


</style>
