// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'


export default createVuetify({

  // components: {
  //   VDataTable,
  // },

  customProperties: true,
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#B79E5B',
          secondary: '#c8d8e4',
          accent: '#795548',
          error: '#f44336',
          warning: '#ffc107',
          info: '#8bc34a',
          success: '#4caf50'
        }
      },
      dark: {
        colors: {
          primary: '#B79E5B',
          secondary: '#c8d8e4',
          accent: '#795548',
        }
      }
    }
  }
})

