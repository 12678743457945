<template>
    <v-card v-if="chartData">
      <Bar id="average-hours-bar" :options="chartOptions" :data="chartData" />
    </v-card>
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js/auto'
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  
  export default {
    name: 'TransactionAverageHoursBar',
    components: { Bar },
    props: {
      chartData: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        chartOptions: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Duração média da transação'
            },
            subtitle: {
              display: true,
              text: 'Média em horas no mês'
            },
            legend: {
              display: false,
            }
          }
        }
      }
    }
  }
  </script>
  