<template>

  <div>

    <v-row class="justify-center">
      <v-col cols="12" md="6" sm="12" class="">
        <v-card :disabled="store.dialogLoader || dialogContact" class="elevation-10">
          <v-toolbar color="primary">

            <v-toolbar-title>Fale com a gente aqui</v-toolbar-title>
            <v-btn icon large link to="/" color="white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pa-4 text-body-1">
              
            <v-text-field class="mb-5" clearable color="primary"
                :readonly="store.userLogged != null"
                v-model="userContact.yourName"
                prepend-icon="mdi-account"
                id="yourName"
                label="Nome"
                placeholder="Seu nome"
                maxlength="20"
                @keyup="userContact.yourName = (userContact.yourName ? userContact.yourName.replace(' ', '') : '')"
                :error-messages="v$.userContact.yourName.$invalid ? ['Campo obrigatório'] : []">
              </v-text-field>

              <v-text-field class="mb-5" clearable color="primary"
                :disabled="v$.userContact.yourName.$invalid"
                v-model="userContact.subject"
                prepend-icon="mdi-chat"
                id="subject"
                label="Assunto"
                placeholder="Digite o assunto"
                maxlength="25"
                :error-messages="v$.userContact.subject.$invalid ? ['Campo obrigatório'] : []">
              </v-text-field>

              <v-text-field class="mb-5" clearable color="primary"
                :readonly="store.userLogged != null"
                :disabled="v$.userContact.subject.$invalid"
                v-model="userContact.yourEmail"
                prepend-icon="mdi-email"
                id="yourEmail"
                label="Email"
                placeholder="Seu email"
                maxlength="120"
                :error-messages="v$.userContact.yourEmail.$invalid ? ['Digite um email válido'] : []">
              </v-text-field>

              <v-textarea class="mb-5" clearable color="primary"
              :disabled="v$.userContact.yourEmail.$invalid"
                v-model="userContact.yourMessage"
                prepend-icon="mdi-pencil"
                id="yourMessage"
                label="Mensagem"
                placeholder="Pode falar!"
                maxlength="250" counter rows="8"
                :error-messages="v$.userContact.yourMessage.$invalid ? ['Campo obrigatório'] : []">
              </v-textarea>

              <v-btn class="mt-2" variant="flat" color="primary" @click="sendContactMessage"
                :disabled="v$.userContact.$invalid" width="100%">
                Enviar
              </v-btn>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto">
        <v-dialog v-model="dialogContact" persistent
          transition="dialog-top-transition"
          width="auto"
        >
          <v-card>
            <v-toolbar
              color="primary"
              title="Mensagem enviada!"
            ></v-toolbar>
            <v-card-text class="text-center">
                <div class="mt-6">
                  Recebemos sua mensagem, obrigado.
                </div>
                <div class="mt-6">
                  Aguarde nosso contato. 
                </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn link to="/"
                variant="text"
              >Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

  </div>
</template>

<script>

import { myMixin } from '../mixin';
import services from '../ws/services';
import { store } from '../store';

import { email, required, minLength, maxLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'


export default {

  setup () {
    return { v$: useVuelidate() }
  },

  mixins: [
    myMixin,
  ],

  computed : {
    
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    userContact : {
      yourName : null,
      subject : null,
      yourEmail : null,
      yourMessage : null
    },

    dialogContact : false,

  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    if(store.userLogged){
      this.userContact.yourName = store.userLogged.firstName;
      this.userContact.yourEmail = store.userLogged.email;
    }
  },

  methods: {

    sendContactMessage(){

      store.dialogLoader = true;

      this.axios({
        method: "post",
        url: services.serviceHost + "/user/sendcontactmessage",
        headers: {
          "Content-Type": "application/json",
        },
        data : this.userContact
      })
      .then(response => {
        console.log('envio da mensagem: ' + response.status);
        store.dialogLoader = false;
        this.dialogContact = true;
      });

      //sendcontactmessage
      
    },

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  },

  validations() {
    
    return {

      userContact : {
        yourName : {
          required,
          minLength: minLength(3),
          maxLength: maxLength(20),
        },  
        subject : {
          required,
          minLength: minLength(5),
          maxLength: maxLength(25),
        },  
        yourEmail : {
          required,
          email
        },
        yourMessage : {
          required,
          minLength: minLength(10),
          maxLength: maxLength(250),
        },    
      }

    }
  }

}

</script>

<style scoped>


</style>
