<template>

  <div>

    <v-card class="elevation-10 mb-4 pa-4 text-center text-body-1">
      Olá! Eu sou Tourmalet, o robô de cripto.
    </v-card>

    <v-row class="d-sm-flex d-none">
      <v-col cols="12" md="3" sm="12" class="">
        <v-card class="elevation-10 mb-4 pa-0 text-center text-body-1">
          <v-btn width="100%" color="grey-darken-3" min-height="60" size="large" @click="dialogHowWorks = true">
            Como funciona?
          </v-btn>
        </v-card>
      </v-col>

      <v-col cols="12" md="3" sm="12" class="">
        <v-card class="elevation-10 mb-4 pa-0 text-center text-body-1">
          <v-btn width="100%" color="grey-darken-3" min-height="60" size="large" @click="dialogIsItForMe = true">
            É para mim?
          </v-btn>
        </v-card>
      </v-col>

      <v-col cols="12" md="3" sm="12" class="">
        <v-card class="elevation-10 mb-4 pa-0 text-center text-body-1" @click="dialogWhatINeed = true">
          <v-btn width="100%" color="grey-darken-3" min-height="60" size="large">
            Do que eu preciso?
          </v-btn>
        </v-card>
      </v-col>

      <v-col cols="12" md="3" sm="12" class="">
        <v-card class="elevation-10 mb-4 pa-0 text-center text-body-1" link to="/account/new">
          <v-btn width="100%" color="grey-lighten-1" min-height="60" size="large">
            Login / Criar conta
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <div>
      <v-card class="elevation-10 mb-4 text-center">
        <v-img :src="imageSrc" alt="Robot Coin Stack" class="responsive-image"></v-img>
      </v-card>
    </div>

    <v-row class="d-sm-none d-flex">
      <v-col cols="12" md="3" sm="12" class="pb-0">
        <v-card class="elevation-10 mb-4 pa-0 text-center text-body-1">
          <v-btn width="100%" color="grey-darken-3" min-height="60" size="large" @click="dialogHowWorks = true">
            Como funciona?
          </v-btn>
        </v-card>
      </v-col>

      <v-col cols="12" md="3" sm="12" class="pb-0 pt-0">
        <v-card class="elevation-10 mb-4 pa-0 text-center text-body-1">
          <v-btn width="100%" color="grey-darken-3" min-height="60" size="large" @click="dialogIsItForMe = true">
            É para mim?
          </v-btn>
        </v-card>
      </v-col>

      <v-col cols="12" md="3" sm="12" class="pb-0 pt-0">
        <v-card class="elevation-10 mb-4 pa-0 text-center text-body-1" @click="dialogWhatINeed = true">
          <v-btn width="100%" color="grey-darken-3" min-height="60" size="large">
            Do que eu preciso?
          </v-btn>
        </v-card>
      </v-col>

      <v-col cols="12" md="3" sm="12" class="pb-0 pt-0">
        <v-card class="elevation-10 mb-4 pa-0 text-center text-body-1" link to="/account/new">
          <v-btn width="100%" color="grey-lighten-1" min-height="60" size="large">
            Login / Criar conta
          </v-btn>
        </v-card>
      </v-col>
    </v-row>




    <v-row justify="center">
      <v-dialog
        v-model="dialogHowWorks"
        fullscreen
        :scrim="false"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>Como funciona?</v-toolbar-title>
            <v-toolbar-items>
              <v-btn
                @click="dialogHowWorks = false"
              >
              <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          
          <v-card-text class="text-justify">
            <div class="text-body-1 mb-6">
              O Tourmalet é um robô que faz trades no mercado de criptoedas. Você precisa de uma conta na Binance, porque é por lá
              que o robô opera o dinheiro. 
            </div>
            <div class="text-body-1 mb-6">
              <span class="font-weight-black">O robô opera meu dinheiro comprando e vendendo?</span>
              <br/>
              Sim. Quando você terminar de configurar o robô, ele passará a comprar e vender para você no mercado de cripto,
              usando a Binance como plataforma. 
            </div>
            <div class="text-body-1 mb-6">
              <span class="font-weight-black">O robô pode perder meu dinheiro?</span>
              <br/>
              Sim. É importante que você saiba que o mercado financeiro tem riscos e que no caso do mercado criptomoedas, 
              estes riscos podem ser ainda maiores. O robô em geral é projetado para fazer um número grande de trades, e ganhar na 
              volatilidade. Ele fechará trades positivas e negativas e espera-se que o resultado seja positivo. Nào há no entanto, 
              garantia de resultado positivo. 
            </div>
            <div class="text-body-1 mb-6">
              <span class="font-weight-black">Eu posso controlar o robô?</span>
              <br/>
              Você pode definir algumas configurações, como por exemplo o valor das suas trades. Pode também ligar e desligar o robô 
              sempre que quiser. Nós recomendamos que você sempre acompanhe os resultados do robô e que em um cenário muito desfavorável 
              do mercado mantenha-o desligado.
            </div>
          </v-card-text>

        </v-card>
      </v-dialog>
    </v-row>



    <v-row justify="center">
      <v-dialog
        v-model="dialogIsItForMe"
        fullscreen
        :scrim="false"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>É para mim?</v-toolbar-title>
            <v-toolbar-items>
              <v-btn
                @click="dialogIsItForMe = false"
              >
              <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          
          <v-card-text class="text-justify">
            <div class="text-body-1 mb-6">
              Primeiro é preciso lembrar que investimentos sempre envolvem algum nível de risco. Investimentos no mercado de 
              criptomoedas envolvem riscos potencialmente maiores que o mercado tradicional. 
            </div>
            <div class="text-body-1 mb-6">
              <span class="font-weight-black">Você conhece o mercado de critpo e as principais moedas?</span>
              <br/>
              Recomendamos que você tenha um conhecimento (ainda que básico) do mercado.
            </div>
            <div class="text-body-1 mb-6">
              <span class="font-weight-black">Qual a vantagem de usar o robô se eu mesmo posso operar no mercado?</span>
              <br/>
              A vantagem de utilizar um robô é garantir que as oportunidaes sejam aproveitadas quando surgirem. Operar sozinho 
              demanda tempo e precisaríamos ficar observando os índices do mercado. O objetivo do robô é resolver esse problema, porque ele 
              monitora os índices e compra/vende quando entende que o momento é favorável. 
            </div>
            <div class="text-body-1 mb-6">
              <span class="font-weight-black">É seguro?</span>
              <br/>
              O robô não saca nem transfere seu dinheiro. Nós recomendamos inclusive que você deixe essa possibilidade bloqueada na sua 
              API da Binance, de maneira que você fique protegido. O robô apenas precisa da autorização para trades, dentro da sua conta. 
              O dinheiro sempre fica dentro da sua conta, nunca é transferido para fora dela. 
            </div>
          </v-card-text>

        </v-card>
      </v-dialog>
    </v-row>



    <v-row justify="center">
      <v-dialog
        v-model="dialogWhatINeed"
        fullscreen
        :scrim="false"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>Do que eu preciso?</v-toolbar-title>
            <v-toolbar-items>
              <v-btn
                @click="dialogWhatINeed = false"
              >
              <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          
          <v-card-text class="text-justify">
            <div class="text-body-1 mb-6">
              Os passos são os seguintes:
            </div>
            <div class="text-body-1 mb-6">
              <ol class="px-6">
                <li>
                  Criar sua conta aqui no Tourmalet
                </li>
                <li>
                  Criar sua conta na Binance
                </li>
                <li>
                  Depositar e ter USDT disponível na Binance
                </li>
                <li>
                  Configurar na Binance uma chave de API
                </li>
                <li>
                  Autorizar trades na sua API que foi criada
                </li>
                <li>
                  Ajustar aqui nas configurações do Tourmalet a sua chave de API
                </li>
                <li>
                  Ativar o seu robô aqui no Tourmalet e ajustar o valor das suas trades
                </li>
              </ol>
            </div>
            <div class="text-body-1 mb-6">
              Pronto! Depois disso, acompanhe as trades feitas pelo robô, para você. 
            </div>
          </v-card-text>

        </v-card>
      </v-dialog>
    </v-row>


  </div>

</template>

<script>


export default {
  data() {
    return {

      imageSrc: require('@/assets/robot-coin-stack.png'),

      dialogHowWorks : false,
      dialogIsItForMe : false,
      dialogWhatINeed : false,
    }
  }
}
</script>

<style scoped>

</style>
