<template>
  <div v-if="transactionCountData && averageHoursData && profitByMonthData">

    <transition name="slide-x-transition" mode="out-in">
      <v-card :disabled="store.dialogLoader" class="elevation-4">
        <v-toolbar color="primary">
          <v-toolbar-title class="d-sm-block d-none">
            Resultados por mês
          </v-toolbar-title>
          <v-toolbar-title class="d-block d-sm-none text-body-2">
            Resultados por mês
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div>
            <ProfitByMonth :chartData="profitByMonthData" :key="'profit-by-month-bar'" ref="profit-by-month-bar"/>
          </div>
        </v-card-text>
      </v-card>
    </transition>

    <transition name="slide-x-transition" mode="out-in">
      <v-card :disabled="store.dialogLoader" class="mt-6 elevation-4">
        <v-toolbar color="primary">
          <v-toolbar-title class="d-sm-block d-none">
            Transações por mês
          </v-toolbar-title>
          <v-toolbar-title class="d-block d-sm-none text-body-2">
            Transações por mês
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div>
            <TransactionCountByMonthBar :chartData="transactionCountData" :key="'count-by-month-bar'" ref="count-by-month-bar"/>
          </div>
        </v-card-text>
      </v-card>
    </transition>

    <transition name="slide-x-transition" mode="out-in">
      <v-card :disabled="store.dialogLoader" class="mt-6 elevation-4">
        <v-toolbar color="primary">
          <v-toolbar-title class="d-sm-block d-none">
            Tempo médio das transações (open / close)
          </v-toolbar-title>
          <v-toolbar-title class="d-block d-sm-none text-body-2">
            Tempo médio das transações (open/close)
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div>
            <TransactionAverageHoursBar :chartData="averageHoursData" :key="'average-hours-bar'" ref="average-hours-bar"/>
          </div>
        </v-card-text>
      </v-card>
    </transition>

    

  </div>
</template>

<script>
import { myMixin } from '../mixin';
import { store } from '../store';
import services from '@/ws/services'

// charts
import TransactionAverageHoursBar from '@/components/chart/TransactionAverageHoursBar.vue';
import TransactionCountByMonthBar from '@/components/chart/TransactionCountByMonthBar.vue';
import ProfitByMonth from '@/components/chart/ProfitByMonth.vue';

export default {
  mixins: [myMixin],
  components: {
    TransactionAverageHoursBar,
    TransactionCountByMonthBar,
    ProfitByMonth,
  },
  data: () => ({
    store,
    averageHoursData: null,
    transactionCountData: null,
    profitByMonthData : null,
  }),
  mounted() {
    this.loadReportData();
  },
  methods: {

    async loadReportData() {
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` },
      };

      try {
        store.dialogLoader = true;

        // Load average transaction time (first request)
        let response = await this.axios.post(services.serviceHost + '/robot/average-time-by-month/12', {}, config);
        const averageData = response.data;
        console.log('\n\n' + JSON.stringify(response.data));

        const months = averageData.map(item => item.transactionMonth);
        const values = averageData.map(item => item.avgTransactionTimeHours);
        this.averageHoursData = {
          labels: months,
          datasets: [
            {
              label: 'Média de horas',
              data: values,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        };

        // Load transaction count by month (second request)
        response = await this.axios.post(services.serviceHost + '/robot/quantity-by-month/12', {}, config);
        const transactionData = response.data;
        console.log('\n\n' + JSON.stringify(response.data));

        const transactionMonths = transactionData.map(item => item.transactionMonth);
        const transactionValues = transactionData.map(item => item.transactionCount);
        this.transactionCountData = {
          labels: transactionMonths,
          datasets: [
            {
              label: 'Quantidade de transações',
              data: transactionValues,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        };

        // Load profit by month (third request)
        response = await this.axios.post(services.serviceHost + '/robot/profit-by-month/12', {}, config);
        const profitData = response.data;
        console.log('\n\n' + JSON.stringify(response.data));

        const profitMonths = profitData.map(item => item.transactionMonth);
        const profitValues = profitData.map(item => item.totalProfit);
        this.profitByMonthData = {
          labels: profitMonths,
          datasets: [
            {
              label: 'Resultados',
              data: profitValues,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        };

      } catch (error) {
        console.error('Error loading report data:', error);
        this.softLogout();
      } finally {
        store.dialogLoader = false;
      }
    }






    // loadReportData() {
    //   const config = {
    //     headers: { Authorization: `Bearer ${store.currentToken}` },
    //   };

    //   // Load average transaction time
    //   this.axios
    //     .post(services.serviceHost + '/robot/average-time-by-month/12', {}, config)
    //     .then(response => {
    //       const data = response.data;
    //       console.log('\n\n' + JSON.stringify(response.data));

    //       const months = data.map(item => item.transactionMonth);
    //       const values = data.map(item => item.avgTransactionTimeHours);
    //       this.averageHoursData = {
    //         labels: months,
    //         datasets: [
    //           {
    //             label: 'Média de horas',
    //             data: values,
    //             backgroundColor: 'rgba(75, 192, 192, 0.2)',
    //             borderColor: 'rgba(75, 192, 192, 1)',
    //             borderWidth: 1,
    //           },
    //         ],
    //       };
    //     });

    //   // Load transaction count by month
    //   this.axios
    //     .post(services.serviceHost + '/robot/quantity-by-month/12', {}, config)
    //     .then(response => {
    //       const data = response.data;
    //       console.log('\n\n' + JSON.stringify(response.data));

    //       const months = data.map(item => item.transactionMonth);
    //       const values = data.map(item => item.avgTransactionTimeHours);
    //       this.transactionCountData = {
    //         labels: months,
    //         datasets: [
    //           {
    //             label: 'Quantidade de transaçõeszz',
    //             data: values,
    //             backgroundColor: 'rgba(75, 192, 192, 0.2)',
    //             borderColor: 'rgba(75, 192, 192, 1)',
    //             borderWidth: 1,
    //           },
    //         ],
    //       };
    //     });
    // },
  }
}
</script>

<style scoped></style>
