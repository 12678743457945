<template>
  <v-row justify="center">
    <v-col cols="12" xl="3" lg="5" md="6" sm="8">
      <v-col cols="12 mb-sm-n8 text-center font-weight-bold" v-if="store.pedidoSaving">
        Faça login ou crie sua conta para prosseguirmos com seu pedido de serviço de {{ store.pedidoSaving.servico.nomeServico }}. 
      </v-col>
      <v-col cols="12 mb-sm-n8 text-center font-weight-bold" v-if="store.pedidoProposal">
        Faça login ou crie sua conta para prosseguirmos com sua proposta de serviço de {{ store.pedidoProposal.servico.nomeServico }}. 
      </v-col>
      <v-card class="mx-2 mt-md-16 elevation-10" :disabled="store.dialogLoader">
        <v-toolbar color="primary">
        <v-toolbar-title><v-icon class="mr-2">mdi-account</v-icon>Login</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-5">
          
          <v-text-field class="mb-5 mt-4" clearable variant="outlined" 
            v-model="form.fieldn"
            id="fieldn"
            label="Email"
            maxlength="100"
            :error-messages="v$.form.fieldn.$invalid ? ['Digite seu email'] : []">
          </v-text-field>

          <v-expand-transition>
            <v-text-field class="mb-5" variant="outlined" 
              :disabled="v$.form.fieldn.$invalid || dialogLoader" v-if="!v$.form.fieldn.$invalid"
              v-model="form.fieldp"
              id="fieldp"
              label="Senha"
              maxlength="20"
              :type="show1 ? 'text' : 'password'"
              :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append-inner="show1 = !show1"
              v-on:keyup.enter="doLogin()"
              counter
              :error-messages="v$.form.fieldp.$invalid ? ['Digite a senha'] : []">
            </v-text-field>
          </v-expand-transition>

            <div class="text-center" v-if="store.loginErrorMessage" transition="slide-x-transition">
              <span class="" style="color : red;">
                {{ store.loginErrorMessage }}
              </span>
            </div>

            <v-expand-transition>
              <div class="" v-if="!v$.form.$invalid">
                <v-btn color="" prepend-icon="mdi-check" class="mt-4 elevation-0" large 
                  :disabled="v$.form.$invalid" 
                  :style="btnCardStyle" elevation="2" width="100%" min-height="70" @click="doLogin()">
                  Entrar</v-btn>
              </div>
            </v-expand-transition>

            <v-expand-transition>
              <div class="mt-4" v-if="!v$.form.fieldn.$invalid">
                <v-btn color="" class="px-4 elevation-0" large link to="/account/new"
                  :style="btnCardStyle" elevation="2" width="100%" min-height="70">
                  Criar Conta</v-btn>
              </div>
            </v-expand-transition>

            <v-expand-transition>
              <div class="mt-4">
                <v-btn color="white" width="100%" text size="small" class="elevation-0"
                  v-if="!v$.form.fieldn.$invalid" @click="openRecoverPassword">
                  esqueci minha senha
                </v-btn>
              </div>
            </v-expand-transition>
          
        </v-card-text>
        
      </v-card>
    </v-col>
  </v-row>

</template>

<script>

import { myMixin } from '../mixin';
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
//import services from '../ws/services'
import { store } from '../store'

//import { aesUtil } from '../aes'


export default {

  setup () {
    return { 
      v$: useVuelidate()
    }
  },

  mixins: [
    myMixin,
  ],

  computed : {
    cardTitleStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
    btnCardStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
  },

  data: () => ({
      
    fieldUser : null,
    fieldPwd : null,

    show1: false,

    form: {
      fieldn: '',
      fieldp: '',
    },

    //stored items:
    storedUsername : null,
    storedPassword : null,

    store,

  }),

  beforeMount(){
    var storage = window.localStorage;
    this.storedUsername = storage.getItem('username');

    if(this.storedUsername && this.storedUsername.length > 10){
      this.storedPassword = storage.getItem('password');

      //this.form.fieldn = aesUtil.decryptString(services.myphrase, this.storedUsername);
      //this.form.fieldp = aesUtil.decryptString(services.myphrase, this.storedPassword);
      //this.doLogin();
    }
  },
  

  methods: {

    openRecoverPassword(){
      store.recoverPassEmail = this.form.fieldn;
      this.$router.push("/recoverPassword");
    },

    doLogin(){
      this.processLogin(this.form.fieldn, this.form.fieldp);
    },
    
  },

  validations() {
    return {
      form: {
        fieldn: {
           required, email 
        },
        fieldp: {
            required, 
            min: minLength(6)
        },
      },
    }
  },



}

</script>

<style scoped>


</style>
