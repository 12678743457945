<template>

  <div>

    <v-expand-transition>
      <v-card :disabled="store.dialogLoader" v-if="robotConfig" class="elevation-10">
        <v-toolbar color="primary">

          <v-toolbar-title>Global Config</v-toolbar-title>
          <v-btn icon large link to="/" color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="">

          <v-row>

            <v-col cols="12">
              <div class="text-body-1">
                Status do Robô: 
                <span v-if="robotConfig.robotOperating" class="text-success">
                  Operando normalmente
                </span>
                <span v-else class="text-error">
                  Parado até {{ robotConfig.stopTransactionsUntil }}hs
                </span>
              </div>
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="12" md="3" sm="12" class="" v-if="!robotConfig.robotOperating">
              <v-btn variant="flat" color="success" width="100%" min-height="50"
                @click="enableRobot" :disabled="store.dialogLoader"> 
                Reativar robô
              </v-btn>
            </v-col>

            <v-col cols="12" md="3" sm="12" class="" v-if="robotConfig.robotOperating">
              <v-select v-model="stopRobotHours" :items="[12, 24, 48, 72, 96]"
                label="Número de horas"
              ></v-select>
            </v-col>

            <v-col cols="12" md="3" sm="12" class="" v-if="robotConfig.robotOperating">
              <v-btn variant="flat" color="error" width="100%" min-height="50"
                @click="showDialogConfirmStopRobot = true" :disabled="!stopRobotHours || store.dialogLoader"> 
                Parar robô
              </v-btn>
            </v-col>

          </v-row>


        </v-card-text>
      </v-card>
    </v-expand-transition>

    <v-row>
      <v-col cols="auto">
        <v-dialog v-model="showDialogConfirmStopRobot"
          transition="dialog-top-transition"
          width="auto" min-width="300" min-height="400"
        >
          <v-card :disabled="store.dialogLoader">
            <v-toolbar
              color="primary"
              title="Parar o robô"
            ></v-toolbar>
            <v-card-text class="text-center text-body-2">

              <div class="">
                Parar o funcionamento do robô?
              </div>
              <div class="mt-6">
                <v-btn variant="flat" color="primary" @click="stopRobot">
                  Parar por {{ stopRobotHours }} horas
                </v-btn>
              </div>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn variant="flat" @click="showDialogConfirmStopRobot = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
          
        </v-dialog>
      </v-col>
    </v-row>

  </div>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

export default {

  mixins: [
    myMixin,
  ],

  computed : {
    
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    robotConfig : null,

    stopRobotHours : null,
    showDialogConfirmStopRobot : false,

  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    this.loadGlobalConfig();
  },

  methods: {

    enableRobot(){

      console.log('enabling robot');

      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
      .post(services.serviceHost + "/global-config/unlock", {}, config)
      .then(response => {
        this.robotConfig = response.data;
        console.log(JSON.stringify(response.data));

        this.stopRobotHours = null;
        this.showDialogConfirmStopRobot = false;
        store.dialogLoader = false;
      })
      .catch(error => {
        console.log(error);
        this.softLogout();
      });
    },

    stopRobot(){

      console.log('stopping robot for hours : ' + this.stopRobotHours);

      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
      .post(services.serviceHost + "/global-config/stop-robot/" + this.stopRobotHours, {}, config)
      .then(response => {
        this.robotConfig = response.data;
        console.log(JSON.stringify(response.data));

        this.stopRobotHours = null;
        this.showDialogConfirmStopRobot = false;
        store.dialogLoader = false;
      })
      .catch(error => {
        console.log(error);
        this.softLogout();
      });
    },

    loadGlobalConfig(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
      .post(services.serviceHost + "/global-config/load", {}, config)
      .then(response => {
        this.robotConfig = response.data;
        console.log(JSON.stringify(response.data));
      })
      .catch(error => {
        console.log(error);
        this.softLogout();
      });
        
    },

    simpleServiceCall(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .get(services.serviceHost + "/user/healthcheck", {}, config)
        .then(response => {
          console.log(response.data);
        });
        if(this.user && !this.user.id){
          this.user.roles = [];
        }
    }

  }

}

</script>

<style scoped>


</style>
