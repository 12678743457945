<template>

  <div>

    <v-row class="justify-center">
      <v-col cols="12" md="6" sm="12" class="">

        <v-card class="elevation-10 mb-4" v-if="!store.isCurrentAPIvalid && store.userLogged">
          <v-card-text class="text-body-1 text-justify">
            <div>Opa! {{ store.userLogged.firstName }}, parece que você ainda não configurou suas chaves de API, ou elas não estão válidas. </div>
            <br/>
            <div>As chaves são necessárias para que o robô consiga compra/vender cripto. </div>
          </v-card-text>
          <v-card-actions class="">
            <v-btn width="100%" 
              color="primary" dark link to="/apiManager" variant="flat" append-icon="mdi-arrow-right">
              Configurar chaves
            </v-btn>  
          </v-card-actions>
        </v-card>

        <v-card class="elevation-10" :disabled="store.dialogLoader || !store.isCurrentAPIvalid" v-if="store.userLogged">
          <v-toolbar color="primary">
            <v-toolbar-title>Configurações do Robô</v-toolbar-title>
          </v-toolbar>
          <v-card-text>

            <v-row>

              <v-col cols="12" class="">
                <div class="mb-2 text-body-1">
                  {{ store.userLogged.myConfiguration && store.userLogged.myConfiguration.enabled ? 
                    'Seu robô está ligado' : 'Seu robô está desligado' }}
                </div>
                <v-switch label="Robô Ligar / Desligar" color="green"
                  v-model="store.userLogged.myConfiguration.enabled"
                ></v-switch>
              </v-col>

              <v-col cols="12" class="">
                <v-text-field clearable
                  label="Valor das Trades (em USDT)" 
                  v-model="tradeValues"
                  maxlength="7"
                  v-mask="'####.##'"
                  type="numeric"
                  hint="Valor padrão das ordens de compra"
                  prepend-inner-icon="mdi-cash"
                  :error-messages="v$.tradeValues.$invalid ?  ['Digite um valor entre 15 e 1.000'] : []"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="">
                <v-btn width="100%" color="" dark variant="outlined" @click="showDialogValorHelp = true">
                  Qual o valor ideal? 
                </v-btn>  
              </v-col>

            </v-row>

          </v-card-text>
          <v-card-actions class="mt-15">
            <v-btn width="100%" :disabled="v$.tradeValues.$invalid"
              color="primary" dark variant="flat" append-icon="mdi-check" @click="saveConfigurations">
              Salvar Configurações 
            </v-btn>  
          </v-card-actions>
          
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto">
        <v-dialog v-model="showDialogValorHelp"
          transition="dialog-top-transition"
          width="auto"
        >
          <v-card>
            <v-toolbar
              color="primary"
              title="Quanto colocar aqui?"
            ></v-toolbar>
            <v-card-text class="text-justify">
                <div class="font-weight-bold font-italic">Valor das trades</div>
                <div class="mt-6">
                  Em primeiro lugar deixa a gente lembrar para você que o mercado de cripto é muito volátil e tem riscos potencialmente altos.
                  Invista apenas a parte do seu dinheiro que você possa correr estes riscos, ok? 
                  <span class="font-weight-black">Não invista em cripto o que você não pode perder. </span>
                </div>
                <div class="mt-6">
                  O ideal para o robô é ter espaço para algumas trades, deixando ainda uma margem para manobrar nas perdas. 
                </div>
                <div class="mt-6">
                  Então se por exemplo você tem 
                  <span class="font-weight-black">100,00 USDT </span>
                  disponíveis na conta para a trades, 
                  você poderia colocar por exemplo trades de 
                  <span class="font-weight-black">22,00 USDT</span>. 
                  Assim o robô teria espaço para 4 trades simultâneas, e ficaria com 12,00 USDT para manobrar nas perdas. 
                </div>
                <div class="mt-6">
                  Esta é apenas uma sugestão, ok? Perfis de investidores podem ser bem diferentes e você deve entender qual o seu perfil.
                </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                @click="showDialogValorHelp = false"
              >Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

  </div>
</template>

<script>

import { myMixin } from '../mixin';
import services from '../ws/services';
import { store } from '../store';

import { required, minValue, maxValue } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

export default {

  mixins: [
    myMixin,
  ],

  setup () {
      return { v$: useVuelidate() }
  },

  computed : {
    
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    tradeValues : null,
    //store.userLogged.myConfiguration.ammountUSDTToBuy

    showDialogValorHelp : false,
  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    if(store.userLogged && store.userLogged.myConfiguration && store.userLogged.myConfiguration.ammountUSDTToBuy){
      this.tradeValues = store.userLogged.myConfiguration.ammountUSDTToBuy;
    }
  },

  methods: {

    saveConfigurations(){

      store.dialogLoader = true;

      console.log('user: ' + store.userLogged.firstName);
      console.log('user: ' + store.userLogged.myConfiguration.ammountUSDTToBuy);
      store.userLogged.myConfiguration.ammountUSDTToBuy = this.tradeValues;

      this.axios({
        method: "post",
        url: services.serviceHost + "/user/config/update",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
        data: JSON.stringify(store.userLogged.myConfiguration)
      }).then(response => {
        store.userLogged = response.data;
        //console.log(JSON.stringify(this.userConfiguration));
        store.dialogLoader = false;

        store.globalSnackbarMessage = 'Dados atualizados';
        store.globalSnackbar = true;
      })
      .catch(error => {
        console.log(error.message);
        this.logout();
      });
    },
        

    simpleServiceCall(){

    const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  },

  validations() {
    
    return {

      tradeValues : {
        required,
        minValue: minValue(15),
        maxValue: maxValue(1000),
      },  
    }
  }

}

</script>

<style scoped>


</style>
