<template>

  <div>
    <div id="topoLista"></div>

    <v-expand-x-transition>
      <div class="floating-button text-center" v-if="position[1] < 60 && !store.dialogLoader">
        <v-btn icon color="primary" v-if="position[1] < 60" class="elevation-6" 
          @click="reloadItemList">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </div>
    </v-expand-x-transition>

    <v-row class="justify-center" v-if="!store.dialogLoader && (!filteredList || filteredList.length == 0)">
      <v-col cols="12" md="6" sm="12" class="">
        <div class="">
          <v-card class="elevation-10 mb-4 text-center text-body-1 pa-3">
            Eu ainda não fiz operações para você. 
          </v-card>
          <v-card class="elevation-10 mb-4 text-center">
            <v-img :src="imageSrc" alt="Robot Coin Stack" class="responsive-image"></v-img>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <div class="" v-if="filteredList && filteredList.length > 10">
      <v-card class="elevation-5 mb-4">
        <v-card-text class="text-right font-weight-black pb-0">
          <v-switch color="green" label="Exibir apenas transações abertas"
            v-model="showOnlyOpenTransactions" class="pb-n5" dense
          ></v-switch>
        </v-card-text>
      </v-card>
    </div>
    
    <div class="" v-if="filteredList && filteredList.length > 0">
      <v-row>
        <v-col cols="12" md="4" sm="12" v-for="item in filteredList" :key="item.id" 
          v-show="(showOnlyOpenTransactions && !item.priceSell && item.transactionStatus.toLowerCase() == 'filled') || !showOnlyOpenTransactions">
          <v-lazy
            :options="{
              threshold: .8
            }"
            :key="item.id"
            min-height="50"
            transition="fade-transition"
          >
            <v-card class="elevation-5 fill-heigh" dense height="100%" :disabled="store.dialogLoader">
              <v-card-title class="pa-4">
                <v-row>
                  <v-col cols="6">
                    <span class="subtitle">{{item.symbol}}</span>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-icon color="green" v-if="item.transactionStatus.toLowerCase() == 'filled' && item.priceSell && item.price < item.priceSell">mdi-arrow-up</v-icon>
                    <v-icon color="red lighten-2" v-if="item.transactionStatus.toLowerCase() == 'filled' && item.priceSell && item.price > item.priceSell">mdi-arrow-down</v-icon>
                  </v-col>
                </v-row>
                
              </v-card-title>
              <v-card-text class="">
                <v-row>
                  <v-col cols="12" class="mobile-transacton-box-date pb-0">
                    Data da Compra: {{item.dtTransaction}}
                  </v-col>
                  <v-col cols="12" class="mobile-transacton-box-date pt-0 pb-0" v-if="item.dtTransactionSell">
                    Data da Venda:&nbsp;&nbsp;&nbsp; {{item.dtTransactionSell}}
                  </v-col>
                  <v-col cols="12" class="mobile-transacton-box-date pt-0 pb-0">
                    <span v-if="item.transactionStatus.toLowerCase() == 'filled'">
                      Status: <span class="font-italic font-weight-black" style="color : rgb(113, 179, 15);">{{item.transactionStatus}}</span>
                    </span>
                  </v-col>
                  <v-col cols="12" class="pt-2 pb-0">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" class="mobile-transacton-box-inner pb-0 font-weight-black">
                    Compra
                  </v-col>
                  <v-col cols="12" class="mobile-transacton-box-inner pt-0">
                    $ {{formataMoedaUSD(item.price) > 0 ? formataMoedaUSD(item.price) : formatDecimalToUSD(item.price, 6)}} USDT
                  </v-col> 
                  <v-col cols="12" class="mobile-transacton-box-inner pb-0 font-weight-black">
                    Venda
                  </v-col>
                  <v-col cols="12" class="mobile-transacton-box-inner pt-0">
                    <span v-if="item.priceSell">
                      $ {{formataMoedaUSD(item.priceSell) > 0 ? formataMoedaUSD(item.priceSell) : formatDecimalToUSD(item.priceSell, 6)}} USDT
                    </span>
                    <span v-else>-</span>
                  </v-col> 
                  <v-col cols="12" class="pt-0 pb-0">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" class="pb-0 font-weight-black">
                    <span  v-if="item.transactionStatus.toLowerCase() == 'filled' && ((item.totalAmmountBuy/item.totalAmmountSell)*100) >= 0 && item.priceSell" 
                      v-bind:style="formatDecimalToUSD((100 - ((item.totalAmmountBuy/item.totalAmmountSell)*100))) >= 0 ? 'color : rgb(113, 179, 15);' : 'color : red;'">
                      <span v-if="formatDecimalToUSD(item.fullProfit, 4) > 0 || formatDecimalToUSD(item.fullProfit, 4) < 0">
                        {{formatDecimalToBRL( (100 - ((item.totalAmmountBuy/item.totalAmmountSell)*100)), 4)}} %
                      </span>
                      <span v-else>0 %</span>
                    </span>
                    <span v-else>-</span>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <span style="color : #99A3A4;" v-if="item.methodOfBuy && item.methodOfBuy.toLowerCase() == 'fi'">(Force Index)</span>
                      <span style="color : #99A3A4;" v-else-if="item.methodOfBuy && item.methodOfBuy.toLowerCase() == 'mm minuto'">(Setup: MM Minuto)</span>
                      <span style="color : #99A3A4;" v-else-if="item.methodOfBuy && item.methodOfBuy.toLowerCase() == 'hi-lo'">(Setup: HiLo)</span>
                      <span style="color : #99A3A4;" v-else-if="item.methodOfBuy && item.methodOfBuy.toLowerCase() == 'rsi'">(Setup: RSI)</span>
                      <span style="color : #99A3A4;" v-else-if="item.methodOfBuy && item.methodOfBuy.toLowerCase() == 'rsi-10-80'">(Setup: RSI 10-80)</span>
                      <span style="color : #99A3A4;" v-else-if="item.methodOfBuy && item.methodOfBuy.toLowerCase() == 'simple'">(Setup: Padrão)</span>
                      <span style="color : #99A3A4;" v-else-if="item.methodOfBuy && item.methodOfBuy.toLowerCase() == 'bollinger'">(Setup: Bollinger)</span>
                      <span style="color : #99A3A4;" v-else-if="item.methodOfBuy">(Setup: {{ item.methodOfBuy }})</span>
                      <span style="color : #99A3A4;" v-else>(Setup: Padrão)</span>
                  </v-col>

                  <v-col cols="12" v-if="item.transactionStatus.toLowerCase() == 'no_coins_to_sell'">
                    <v-btn @click="showHelpSettledByUser = !showHelpSettledByUser"
                      variant="elevated" color="error" width="100%"
                    >
                      Liquidada pelo usuário 
                      <v-icon color="" class="ml-4">
                        mdi-chat-question-outline
                      </v-icon>
                    </v-btn>  
                  </v-col>

                  <v-col cols="12" v-if="item.transactionStatus.toLowerCase() == 'filled'">
                    <v-btn @click="openTransactionDetail(item)"
                      variant="outlined" color="primary" width="100%"
                    >
                      Ver Detalhes
                      <v-icon color="" class="ml-4">
                        mdi-magnify
                      </v-icon>
                    </v-btn>  
                  </v-col>

                </v-row>
              </v-card-text>
            </v-card>
          </v-lazy>
        </v-col>
      </v-row>
    </div>


    <!-- transaction detail -->
    <v-row>
      <v-col cols="auto">
        <v-dialog v-model="showDialogOrderDetail"
          transition="dialog-top-transition"
          width="auto" 
        >
          <v-card v-if="orderDetail">
            <v-toolbar
              color="primary"
              :title="orderDetail.symbol"
            ></v-toolbar>
            <v-card-text class="text-justify">

              <div class="text-body-1 font-weight-black mb-4">
                Compra: 
                <span style="color : #99A3A4;" v-if="orderDetail.methodOfBuy && orderDetail.methodOfBuy.toLowerCase() == 'fi'">(Force Index)</span>
                <span style="color : #99A3A4;" v-else-if="orderDetail.methodOfBuy && orderDetail.methodOfBuy.toLowerCase() == 'mm minuto'">(Setup: MM Minuto)</span>
                <span style="color : #99A3A4;" v-else-if="orderDetail.methodOfBuy && orderDetail.methodOfBuy.toLowerCase() == 'hi-lo'">(Setup: HiLo)</span>
                <span style="color : #99A3A4;" v-else-if="orderDetail.methodOfBuy && orderDetail.methodOfBuy.toLowerCase() == 'rsi'">(Setup: RSI)</span>
                <span style="color : #99A3A4;" v-else-if="orderDetail.methodOfBuy && orderDetail.methodOfBuy.toLowerCase() == 'rsi-10-80'">(Setup: RSI 10-80)</span>
                <span style="color : #99A3A4;" v-else-if="orderDetail.methodOfBuy && orderDetail.methodOfBuy.toLowerCase() == 'simple'">(Setup: Padrão)</span>
                <span style="color : #99A3A4;" v-else-if="orderDetail.methodOfBuy && orderDetail.methodOfBuy.toLowerCase() == 'bollinger'">(Setup: Bollinger)</span>
                <span style="color : #99A3A4;" v-else-if="orderDetail.methodOfBuy">(Setup: {{ orderDetail.methodOfBuy }})</span>
                <span style="color : #99A3A4;" v-else>(Setup: Padrão)</span>
              </div>
              <v-row>
                <v-col cols="12" md="6" sm="12" class="pt-2 pb-0">
                  <v-text-field readonly
                    label="Data da Compra"
                    v-model="orderDetail.dtTransaction"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-2 pb-0">
                  <v-text-field readonly
                    label="Preço de Compra"
                    v-model="orderDetail.price"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-text-field readonly
                    label="Trade ID na Binance"
                    v-model="orderDetail.binanceOrderId"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-text-field readonly
                    label="Quantidade"
                    v-model="orderDetail.quantity"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-text-field readonly
                    label="Moeda da Taxa"
                    v-model="orderDetail.commissionAsset"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-text-field readonly
                    :label="'Valor da Taxa (' + orderDetail.commissionAsset + ')'"
                    v-model="orderDetail.commission"
                  ></v-text-field>
                </v-col>
              </v-row>


              <v-row v-if="orderDetail.dtTransactionSell && orderDetail.priceSell">
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>

                <v-col cols="12" class="text-body-1 font-weight-black">
                  Venda (
                  <span  v-if="selectedOrder.transactionStatus.toLowerCase() == 'filled' && ((selectedOrder.totalAmmountBuy/selectedOrder.totalAmmountSell)*100) >= 0 && selectedOrder.priceSell" 
                    v-bind:style="formatDecimalToUSD((100 - ((selectedOrder.totalAmmountBuy/selectedOrder.totalAmmountSell)*100))) >= 0 ? 'color : rgb(113, 179, 15);' : 'color : red;'">
                    <span v-if="formatDecimalToUSD(selectedOrder.fullProfit, 4) > 0 || formatDecimalToUSD(selectedOrder.fullProfit, 4) < 0">
                      {{formatDecimalToBRL( (100 - ((selectedOrder.totalAmmountBuy/selectedOrder.totalAmmountSell)*100)), 4)}} %
                    </span>
                    <span v-else>0 %</span>
                  </span>
                  <span v-else>-</span>
                  )
                </v-col>

                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-text-field readonly
                    label="Data da Venda"
                    v-model="orderDetail.dtTransactionSell"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-text-field readonly
                    label="Preço de Venda"
                    v-model="orderDetail.priceSell"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-text-field readonly
                    label="Trade ID na Binance"
                    v-model="orderDetail.binanceOrderIdSell"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-text-field readonly
                    label="Quantidade"
                    v-model="orderDetail.quantitySell"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-text-field readonly
                    label="Moeda da Taxa"
                    v-model="orderDetail.commissionAssetSell"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-text-field readonly
                    :label="'Valor da Taxa (' + orderDetail.commissionAssetSell + ')'"
                    v-model="orderDetail.commissionSell"
                  ></v-text-field>
                </v-col>

              </v-row>

            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                @click="showDialogOrderDetail = false"
              >Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto">
        <v-dialog v-model="showHelpSettledByUser"
          transition="dialog-top-transition"
          width="auto"
        >
          <v-card>
            <v-toolbar
              color="primary"
              title="O que significa?"
            ></v-toolbar>
            <v-card-text class="text-justify">
                <div class="font-weight-bold font-italic">Liquidada pelo usuário</div>
                <div class="mt-6">
                  Quando o robô tentou liquidar esta transação, ele não encontrou a moeda disponível para venda na sua conta. 
                </div>
                <div class="mt-6">
                  Isso pode ter acontecido porque você mesmo liquidou a transação, vendendo suas moedas, ou porque suas moedas 
                  foram colocadas em alguma aplicação automática (Pools de Earing, por exemplo), por você. 
                </div>
                <div class="mt-6">
                  Isso não é exatamente um problema. O robô vai ignorar esta transação e considera-la finalizada.
                </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                @click="showHelpSettledByUser = false"
              >Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-expand-x-transition>
      <div class="floating-button text-center" v-if="position[1] > 300">
        <v-btn icon color="grey-darken-2" v-if="position[1] > 300" class="elevation-6" 
          @click="scrollToTheTop">
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </div>
    </v-expand-x-transition>

  </div>
  
</template>

<script>

import { myMixin } from '../mixin';
import services from '../ws/services';
import { store } from '../store';
import { VCard } from 'vuetify/lib/components/index.mjs';

import windowScrollPosition from '@/mixins/window-scroll-position';

export default {
    mixins: [
        myMixin,
        windowScrollPosition('position'),
    ],
    
    computed: {},

    data: () => ({

      imageSrc: require('@/assets/robot-sit.png'),

      showHelpSettledByUser : false,
      
      errorMessage: null,
      store,
      isSmallScreen: false,
      //fields do filtro:
      transacaoResult: 'all',
      selectedCoin: '',
      coinList: [],
      filteredList: [],
      items: [],

      orderDetail : null,
      selectedOrder : null,
      showDialogOrderDetail : false,

      showOnlyOpenTransactions : false,

    }),
    beforeMount() {
        this.isSmallScreen = this.$vuetify.display.mobile;
    },
    mounted() {
        //this.simpleServiceCall();
        //this.loadBasicGlobalData();
        this.reloadItemList();
    },
    methods: {

      openTransactionDetail(selectedTransaction){

        //console.log('opening details for transaction: ' + selectedTransaction.id);
        this.selectedOrder = selectedTransaction;

        store.dialogLoader = true;
        const config = {
          headers: { Authorization: `Bearer ${store.currentToken}` }
        };
        this.axios
          .post(services.serviceHost + "/robot/order/id/" + selectedTransaction.id, {}, config)
          .then(response => {
          this.orderDetail = response.data;
          console.log(JSON.stringify(response.data));
          store.dialogLoader = false;
          this.showDialogOrderDetail = true;
        })
        .catch(error => {
          console.log(error.message);
          this.softLogout();
        });
      },

      reloadItemList() {
          store.dialogLoader = true;
          const config = {
              headers: { Authorization: `Bearer ${store.currentToken}` }
          };
          this.axios
              .post(services.serviceHost + "/robot/order/my/all", {}, config)
              .then(response => {
              this.items = response.data;
              //console.log(JSON.stringify(this.items));
              store.dialogLoader = false;
              //this.updateCoinList();
              console.log('list size: ' + this.items.length);
              // atualizando lista filtrada:
              this.filteredList = [];
              for(var i = 0; i < this.items.length; i++){
                if(this.addItem(this.items[i])){
                  this.filteredList.push(this.items[i]);
                }
              }
          })
              .catch(error => {
              console.log(error.message);
              this.logout();
          });
      },
      
      addItem(myItem) {
          let result = true;
          if (myItem && this.selectedCoin && myItem.symbol != this.selectedCoin) {
              result = false;
          }
          if (this.transacaoResult != 'all') {
              if (this.transacaoResult == 'positive' && myItem.price > myItem.priceSell) {
                  result = false;
              }
              if (this.transacaoResult == 'negative' && myItem.price < myItem.priceSell) {
                  result = false;
              }
          }
          return result;
      },
      updateCoinList() {
          this.coinList = [];
          if (this.items) {
              for (var i = 0; i < this.items.length; i++) {
                  if (!this.coinList.includes(this.items[i].symbol)) {
                      this.coinList.push(this.items[i].symbol);
                  }
              }
          }
        },
        simpleServiceCall() {
            const config = {
                headers: { Authorization: `Bearer ${store.currentToken}` }
            };
            this.axios
                .get(services.serviceHost + "/user/healthcheck", {}, config)
                .then(response => {
                console.log(response.data);
            });
            if (this.user && !this.user.id) {
                this.user.roles = [];
            }
        },

        scrollToTheTop(){
          //window.scrollTo(0,0);

          const myEl = this.$refs.topoLista || this.$el || document.getElementById('topoLista');
          this.$smoothScroll({
            scrollTo: myEl,
            offset: -100,
          });
        },
    },

    components: { VCard }
}

</script>

<style scoped>


</style>
