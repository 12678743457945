<template>
  <v-card :disabled="store.dialogLoader">
    <v-toolbar color="primary">
      <v-toolbar-title>Gerenciar moedas</v-toolbar-title>
      <v-btn icon large link to="/" color="white">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <!-- Filter Input Field -->
      <v-text-field
        v-model="search"
        label="Filtrar moedas"
        append-icon="mdi-magnify"
        single-line
        hide-details
      ></v-text-field>

      <!-- Checkbox to show only enabled items -->
      <v-checkbox
        v-model="showOnlyEnabled"
        label="Mostrar apenas habilitados"
        class="mt-3"
      ></v-checkbox>

      <!-- Button to add a new coin -->
      <v-btn color="primary" @click="openDialog" class="mt-3">
        Adicionar Moeda
      </v-btn>

      <!-- Data Table with Headers -->
      <v-data-table
        :headers="headers"
        :items="filteredSymbols"
        item-value="id"
        class="elevation-1 mt-4"
        :loading="loading"
        loading-text="Carregando moedas..."
        disable-pagination
      >
        <template v-slot:item.enabled="{ item }">
          <v-switch
            v-model="item.enabled"
            color="green"
            @change="toggleStatus(item)"
            :label="item.enabled ? 'Enabled' : 'Disabled'"
          ></v-switch>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn @click="openDialog(item)">Editar</v-btn>
        </template>
      </v-data-table>

      <!-- Dialog for Adding/Editing Coins -->
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ editMode ? 'Editar Moeda' : 'Adicionar Moeda' }}</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="currentSymbol.symbol"
              label="Symbol"
              required
            ></v-text-field>
            <v-text-field
              v-model="currentSymbol.minQty"
              label="Min Qty"
              required
            ></v-text-field>
            <v-text-field
              v-model="currentSymbol.maxQty"
              label="Max Qty"
              required
            ></v-text-field>
            <v-text-field
              v-model="currentSymbol.stepSize"
              label="Step Size"
              required
            ></v-text-field>
            <v-text-field
              v-model="currentSymbol.scale"
              label="Scale"
              type="number"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="closeDialog">Cancelar</v-btn>
            <v-btn color="primary" @click="saveSymbol">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

export default {
  mixins: [myMixin],
  data: () => ({
    store,
    loading: false,
    symbols: [],
    search: '',
    showOnlyEnabled: false,
    dialog: false,  // Control the dialog visibility
    currentSymbol: {},  // Current symbol data for editing or adding
    editMode: false,  // Flag to check if in edit mode
    headers: [
      { title: 'Symbol', align: 'start', key: 'symbol' },
      { title: 'Min Qty', align: 'end', key: 'minQty' },
      { title: 'Max Qty', align: 'end', key: 'maxQty' },
      { title: 'Step Size', align: 'end', key: 'stepSize' },
      { title: 'Scale', align: 'center', key: 'scale' },
      { title: 'Enabled', align: 'center', key: 'enabled' },
      { title: 'Ações', align: 'center', key: 'action' },
    ],
  }),

  mounted() {
    this.fetchSymbols();
  },

  computed: {
    filteredSymbols() {
      return this.symbols.filter((symbol) => {
        const matchesSearch = symbol.symbol.toLowerCase().includes(this.search.toLowerCase());
        const matchesEnabled = this.showOnlyEnabled ? symbol.enabled : true;
        return matchesSearch && matchesEnabled;
      });
    },
  },

  methods: {
    fetchSymbols() {
      this.loading = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` },
      };
      this.axios
        .post(services.serviceHost + '/symbol/all', {}, config)
        .then((response) => {
          this.symbols = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleStatus(symbol) {
      const updatedSymbol = { ...symbol, enabled: !symbol.enabled };
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` },
      };
      this.axios
        .post(services.serviceHost + '/symbol/save', updatedSymbol, config)
        .catch((error) => {
          console.error('Error updating status:', error);
        });
    },
    openDialog(symbol = {}) {
      this.currentSymbol = { ...symbol }; // Copy the symbol data for editing or create a new one
      this.editMode = !!symbol.id; // Set edit mode if there is an id
      this.dialog = true; // Open the dialog
    },
    closeDialog() {
      this.dialog = false; // Close the dialog
      this.currentSymbol = {}; // Reset the current symbol
    },
    saveSymbol() {
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` },
      };

      // Add or update symbol
      const apiEndpoint = this.editMode 
        ? `${services.serviceHost}/symbol/save` 
        : `${services.serviceHost}/symbol/add`;

      this.axios
        .post(apiEndpoint, this.currentSymbol, config)
        .then(() => {
          this.fetchSymbols(); // Refresh the list after saving
          this.closeDialog(); // Close the dialog
        })
        .catch((error) => {
          console.error('Error saving symbol:', error);
        });
    },
  },
};
</script>

<style scoped>
</style>
