<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-justify">
        <v-typography class="headline">Política de Privacidade do Tourmalet</v-typography>

        <v-divider class="mt-2 mb-2"></v-divider>

        <p>Agradecemos pela sua escolha e confiança na plataforma Tourmalet, o seu trader de criptomoedas seguro e eficaz. Respeitamos a sua privacidade e estamos comprometidos em proteger os seus dados pessoais. Esta política de privacidade irá informá-lo sobre como tratamos os seus dados pessoais quando você usa nosso aplicativo e informa sobre os seus direitos de privacidade e como a lei protege você.</p>
        <br />
        <v-typography class="title mt-4">Coleta de Informações</v-typography>
        <p>Nós coletamos apenas as informações estritamente necessárias para o funcionamento do app, que incluem detalhes para criar e administrar sua conta conosco, como nome, endereço de e-mail, e dados da API.</p>
        <p>Além disso, também registramos dados relacionados às transações que você realiza por meio do nosso aplicativo, para garantir a segurança e rastreabilidade de todas as suas ações.</p>
        <br />
        <v-typography class="title mt-4">Uso das Informações</v-typography>
        <p>Nós usamos as informações que coletamos para fornecer, manter, proteger e melhorar o aplicativo Tourmalet, para desenvolver novos serviços e para proteger a nós e aos nossos usuários. Também usamos essas informações para oferecer a você um conteúdo personalizado, como resultados de pesquisa mais relevantes.</p>
        <br />
        <v-typography class="title mt-4">Segurança das Informações</v-typography>
        <p>Todos os seus dados e transações são criptografados com tecnologia de ponta. Isso significa que, mesmo em um evento altamente improvável de interceptação dos dados, eles estarão seguros e inacessíveis.</p>
        <p>Não compartilhamos, vendemos ou alugamos suas informações pessoais para terceiros para fins de marketing sem o seu consentimento explícito. Estamos comprometidos em proteger a privacidade e a segurança de suas informações pessoais.</p>
        <br />
        <v-typography class="title mt-4">Seus Direitos</v-typography>
        <p>De acordo com as leis de privacidade de dados, você tem direitos que você pode exercer sob determinadas circunstâncias em relação aos seus dados pessoais que possuímos:</p>
        <v-list>
          <v-list-item-group>
            <v-list-item>
              <v-list-item-content>Direito de acesso: você tem o direito de solicitar uma cópia das informações que temos sobre você.</v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>Direito de retificação: você tem o direito de corrigir dados que consideramos incorretos. Você também pode completar as informações que considera incompletas.</v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>Direito ao apagamento: sob certas condições, você tem o direito de solicitar que seus dados pessoais sejam apagados de nossos sistemas.</v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <br />
        <p>Se você deseja exercer algum dos direitos acima, entre em contato conosco.</p>
        <br />
        <p>Lembramos que podemos fazer alterações nesta Política de Privacidade de tempos em tempos. Recomendamos que você reveja esta Política de Privacidade regularmente para garantir que esteja ciente de qualquer alteração e de como suas informações podem ser usadas.</p>
        <br />
        <p>Caso você tenha dúvidas ou questões sobre a nossa Política de Privacidade, não hesite em nos contatar.</p>
        <br />
        <p><strong>Última atualização: 1 de Agosto de 2023.</strong></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
}
</script>

<style scoped>
/* Se necessário, você pode adicionar estilos personalizados aqui */
</style>
