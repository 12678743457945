<template>

  <div> 

    <v-row justify="center">
      <v-col cols="12" xl="3" lg="5" md="6" sm="8">
  
      <v-card :disabled="store.dialogLoader" class="elevation-10">
        <v-toolbar color="primary">

          <v-toolbar-title>Criar Conta</v-toolbar-title>
          <v-btn icon large link to="/" color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
        
          <v-text-field class="mb-5" clearable color="primary"
            v-model="newUser.firstName"
            prepend-icon="mdi-account"
            id="firstName"
            label="Nome"
            placeholder="Seu nome"
            maxlength="15"
            @keyup="newUser.firstName = (newUser.firstName ? newUser.firstName.replace(' ', '') : '')"
            :error-messages="v$.newUser.firstName.$invalid ? ['Campo obrigatório'] : []">
          </v-text-field>

          <v-text-field class="mb-5" clearable color="primary"
            :disabled="v$.newUser.firstName.$invalid"
            v-model="newUser.lastName"
            prepend-icon="mdi-account"
            id="lastName"
            label="Sobrenome"
            placeholder="Seu sobrenome"
            maxlength="15"
            :error-messages="v$.newUser.firstName.$invalid ? ['Campo obrigatório'] : []">
          </v-text-field>

          <v-text-field class="mb-5" clearable color="primary"
            v-model="newUser.email"
            :disabled="v$.newUser.lastName.$invalid"
            prepend-icon="mdi-email-outline"
            id="email"
            label="Email"
            placeholder="Entre com seu email"
            maxlength="150"
            @click:clear="errorMessageMail = null"
            :error-messages="v$.newUser.email.$invalid ? ['Digite um email válido'] : []">
          </v-text-field>

          <v-expand-transition>
            <div class="font-weight-black text-body-2 text-center" v-if="!v$.newUser.email.$invalid">
              Sua senha incial será enviada <br />para o seu e-mail
            </div>
          </v-expand-transition> 


          <!-- <v-text-field class="mb-5" counter color="primary"
            v-model="newUser.pwd"
            :disabled="v$.newUser.email.$invalid"
            prepend-icon="mdi-key-outline"
            id="password"
            label="Senha"
            placeholder="digite a senha"
            hint="entre 6 e 15 caracteres"
            maxlength="15"
            :type="show1 ? 'text' : 'password'"
            :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-inner="show1 = !show1"
            :error-messages="v$.newUser.pwd.$invalid ? ['Digite uma senha válida'] : []">
          </v-text-field>

          <v-text-field class="mb-5" counter color="primary"
            :disabled="v$.newUser.pwd.$invalid"
            v-model="newUser.confirmPwd"
            prepend-icon="mdi-key-outline"
            id="confirmPassword"
            label="Confirme a senha"
            placeholder="confirme a senha"
            hint="entre 6 e 15 caracteres"
            maxlength="15"
            :type="show2 ? 'text' : 'password'"
            :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-inner="show2 = !show2"
            :error-messages="v$.newUser.confirmPwd.$invalid ? ['Confirmação inválida'] : []">
          </v-text-field> -->

        </v-card-text>

        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>
          <v-btn variant="flat" color="primary" :disabled="v$.newUser.$invalid" @click="createUserAccount"
            class="" text width="100%">
            Criar!</v-btn>
        </v-card-actions>
      </v-card>

    </v-col>
  </v-row>

    <v-row>
      <v-col cols="auto">
        <v-dialog v-model="accountCreatedDialog"
          transition="dialog-top-transition"
          width="auto"
        >
          <v-card>
            <v-toolbar
              color="primary"
              title="Sucesso"
            ></v-toolbar>
            <v-card-text class="text-justify">
                <div class="font-weight-bold font-italic">Conta criada</div>
                <div class="mt-6">
                  Sua senha inicial foi enviada para o seu email. 
                </div>
                <div class="mt-6">
                  Recomendamos que você mude sua senha no primeiro acesso.
                </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                @click="closeSuccessModal"
              >Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

  </div>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

import { email, required, minLength, maxLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

export default {

  setup () {
      return { v$: useVuelidate() }
  },

  mixins: [
    myMixin,
  ],

  computed : {
    
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    //fields to the form:
    newUser : {
      firstName : null,
      lastName : null,
      email : null,
      pwd : null,
      confirmPwd : null,
    },

    show1 : false,
    show2 : false,

    errorMessageMail : null,

    accountCreatedDialog : null,

  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    //this.simpleServiceCall();
    //this.loadBasicGlobalData();
  },

  methods: {

    createUserAccount(){

      store.dialogLoader = true;


      // let text1Cypher = aesUtil.encryptString(services.myphrase, "gerosio");
      // let text2Cypher = aesUtil.encryptString(services.myphrase, "clauderson");
      let myData = {
          firstName : this.capitalizeString(this.newUser.firstName),
          lastName : this.capitalizeString(this.newUser.lastName),
          email : this.newUser.email.toLowerCase(),
      };

      this.axios({
        method: "post",
        url: services.serviceHost + "/user/createaccount",
        headers: {
          "Content-Type": "application/json",
        },
        data : myData
      })
      .then(response => {
        console.log('saved user id: ' + response.status);
        store.dialogLoader = false;
        this.accountCreatedDialog = true;
      });

    },  

    closeSuccessModal(){
      this.$router.push('/');
    },

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  },

  validations() {
    
    return {

      newUser : {
        firstName : {
          required,
          minLength: minLength(3),
          maxLength: maxLength(15),
        },  
        lastName : {
          required,
          minLength: minLength(3),
          maxLength: maxLength(15),
        },  
        email : {
          required,
          email,
        },  
        // pwd : {
        //   required,
        //   minLength: minLength(6),
        //   maxLength: maxLength(15),
        // },  
        // confirmPwd: {
        //   required,
        //   minLength: minLength(6),
        //   maxLength: maxLength(15),
        //   sameAs: sameAs(this.newUser.pwd),
        // },
      }
    }
  }

}

</script>

<style scoped>


</style>
