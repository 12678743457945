<template>
  <v-card :disabled="store.dialogLoader">
    <v-toolbar color="primary">

      <v-toolbar-title>Suas Assinaturas</v-toolbar-title>
      <v-btn icon large link to="/" color="white">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="text-h5 pa-12">
        Home...
      </div>
      <div class="text-h5 pa-12" v-if="store.userLogged">
        {{ store.userLogged.fullName }}
      </div>
    </v-card-text>
    <v-card-actions class="pa-5">
      <v-btn variant="outlined" color="primary"
        class="" width="100%" @click="subscribe">
        Assinar!</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import { myMixin } from '../mixin';
import services from '../ws/services';
import { store } from '../store';

export default {

  mixins: [
    myMixin,
  ],

  computed : {
    
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : false,
  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    
    document.addEventListener('deviceready', () => {
      store.verbosity = store.INFO; // or store.DEBUG for full debug output

      // Register your products
      store.register({
        type: window.CdvPurchase.PAID_SUBSCRIPTION,
        id: "cripto.robot.tourmalet.month",
        platform: window.CdvPurchase.Platform.APPLE_APPSTORE,
      });

      // Refresh the store to update the product's state
      store.refresh();
    });


  },

  methods: {

    subscribe(){

      console.log('procesing subscription...');
      store.order("cripto.robot.tourmalet.month");
    },

    simpleServiceCall(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .get(services.serviceHost + "/user/healthcheck", {}, config)
        .then(response => {
          console.log(response.data);
        });
        if(this.user && !this.user.id){
          this.user.roles = [];
        }
    }

  }

}

</script>

<style scoped>


</style>
