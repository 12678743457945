<template>

  <div>

    <v-row class="justify-center">
      <v-col cols="12" md="6" sm="12" class="">

        <v-expand-x-transition>
          <v-card class="elevation-10 mb-10 pa-6" v-if="store.isCurrentAPIvalid" :disabled="store.dialogLoader">
            <v-row>
              <v-col cols="12">
                <v-btn icon color="green-darken-2" size="small" class="mr-8">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                {{ store.dialogLoader ? 'Verificando...' : 'Tudo certo com a sua API atual' }}
              </v-col>
            </v-row>
          </v-card>
        </v-expand-x-transition>

        <v-expand-x-transition>
          <v-card class="elevation-10 mb-10 pa-6" v-if="!store.isCurrentAPIvalid" :disabled="store.dialogLoader">
            <v-row>
              <v-col cols="12">
                <v-btn icon color="red-darken-2" size="small" class="mr-4">
                  <v-icon>mdi-alert-circle</v-icon>
                </v-btn>
                {{ store.dialogLoader ? 'Verificando...' : 'API não configurada' }}
              </v-col>
            </v-row>
          </v-card>
        </v-expand-x-transition>

        <v-btn class="mb-4 elevation-5" variant="outlined" width="100%" @click="store.dialogHelpApi = true">
          Instruções para configurar
        </v-btn>

        <v-expand-x-transition>
          <v-card class="elevation-10" :disabled="store.dialogLoader">
            <v-toolbar
            color="primary"
            >
                <v-toolbar-title class="">
                    Atualizar minha API
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pt-6">
              <v-text-field class="" label="Chave de API" v-model="myNewAPiText1"
                maxlength="80" clearable @keyup="newAPIChecked = false; newAPICheckedOk = false;">
              </v-text-field>
              <v-text-field class="" label="Senha de API" v-model="myNewAPiText2"
                maxlength="80" clearable @keyup="newAPIChecked = false; newAPICheckedOk = false;">
              </v-text-field>

              <v-expand-x-transition>
                <div v-if="newAPIChecked && !newAPICheckedOk && !store.dialogLoader">
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <p>
                        <span class="text-error font-weight-black">API inválida</span>
                      </p>
                      <p>
                        <span class="text-error font-weight-black">Verique:</span>
                      </p>
                      <p>
                        <span class="text-error font-weight-black"><v-icon>mdi-arrow-right</v-icon> chave/senha estão corretas</span>
                      </p>
                      <p>
                        <span class="text-error font-weight-black"><v-icon>mdi-arrow-right</v-icon> a API tem autorização para trades</span>
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </v-expand-x-transition>
              <v-expand-x-transition>
                <div v-if="newAPIChecked && newAPICheckedOk && !store.dialogLoader">
                  <v-row>
                    <v-col cols="12" class="text-center text-body-1">
                      <p>
                        <span class="text-success font-weight-black">Tudo certo com a API!</span>
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </v-expand-x-transition>
            </v-card-text>
            <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <v-expand-x-transition>
              <v-btn v-if="!newAPIChecked || !newAPICheckedOk && !store.dialogLoader"
                variant="flat" color="primary" @click="testAPI" 
                class="" text width="100%" min-height="60"
                :disabled="!myNewAPiText1 || myNewAPiText1.length < 40 || !myNewAPiText2 || myNewAPiText2.length < 40 || (newAPIChecked && !newAPICheckedOk)">
                Testar API
              </v-btn>
            </v-expand-x-transition>
            <v-expand-x-transition>
              <v-btn v-if="newAPIChecked && newAPICheckedOk && !store.dialogLoader"
                variant="flat" color="primary" @click="saveNewAPI" 
                class="" text width="100%" min-height="60"
                :disabled="!myNewAPiText1 || myNewAPiText1.length < 40 || !myNewAPiText2 || myNewAPiText2.length < 40">
                Salvar Essa API
              </v-btn>
            </v-expand-x-transition>
          </v-card-actions>
            
          </v-card>
        </v-expand-x-transition>

      </v-col>
    </v-row>

    </div>
</template>

<script>

import { myMixin } from '../mixin';
import services from '../ws/services';
import { store } from '../store';

//import { aesUtil } from '../aes'
//import CryptoJS from 'crypto-js';

export default {

  mixins: [
    myMixin,
  ],

  computed : {
    
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    myNewAPiText1 : null,
    myNewAPiText2 : null,
    newAPIChecked : false,
    newAPICheckedOk : false,

    resultSummary : null,
  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    //this.simpleServiceCall();
    //this.loadBasicGlobalData();
    this.testLoggedUserAPI();
    
  },

  methods: {

    testLoggedUserAPI(){

      store.dialogLoader = true;

      this.axios({
        method: "post",
        url: services.serviceHost + "/user/check-user-binance-api",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
      })
      .then(response => {
        console.log('api valid: ' + response.data);
        store.isCurrentAPIvalid = response.data;
        store.dialogLoader = false;
      })
      .catch(error => {
        console.log(error.status);
        store.dialogLoader = false;
        this.logout();
      });

    },  

    saveNewAPI(){

      store.dialogLoader = true;

      this.newAPIChecked = true;
      let text1Cypher = this.reverseString(this.myNewAPiText1);
      let text2Cypher = this.reverseString(this.myNewAPiText2);

      // let text1Cypher = aesUtil.encryptString(services.myphrase, "gerosio");
      // let text2Cypher = aesUtil.encryptString(services.myphrase, "clauderson");
      let myData = {
          text1 : text1Cypher,
          text2 : text2Cypher,
      };

      this.axios({
        method: "post",
        url: services.serviceHost + "/user/save-new-user-binance-api",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
        data : myData
      })
      .then(response => {
        console.log('saved api valid: ' + response.data);
        store.isCurrentAPIvalid = response.data;

        this.myNewAPiText1 = null;
        this.myNewAPiText2 = null;
        this.newAPIChecked = false;
        this.newAPICheckedOk = false;
        this.store.globalSnackbarMessage = 'API Salva com sucesso!';
        this.store.globalSnackbar = true;
        store.dialogLoader = false;

        //let's go the home:
        this.$router.push("/");
      });

    },  


    testAPI(){

      store.dialogLoader = true;

      this.newAPIChecked = true;
      let text1Cypher = this.reverseString(this.myNewAPiText1);
      let text2Cypher = this.reverseString(this.myNewAPiText2);

      // let text1Cypher = aesUtil.encryptString(services.myphrase, "gerosio");
      // let text2Cypher = aesUtil.encryptString(services.myphrase, "clauderson");
      let myData = {
          text1 : text1Cypher,
          text2 : text2Cypher,
      };

      this.axios({
        method: "post",
        url: services.serviceHost + "/user/check-binance-api",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
        data : myData
      })
      .then(response => {
        console.log('api valid: ' + response.data);
        this.newAPICheckedOk = response.data;
        store.dialogLoader = false;
      });

    },
    

  }

}

</script>

<style scoped>


</style>
