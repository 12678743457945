<template>
  <v-card>
    <v-layout>
      <v-app-bar color="primary" prominent>
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title class="text-body-1 text-sm-h5">Tourmalet Cripto Robot</v-toolbar-title>
        <!-- <v-toolbar-title v-if="store.userLogged">{{ store.userLogged.fullName }}</v-toolbar-title> -->

        <!-- <v-spacer></v-spacer> -->

        <v-divider vertical class="ml-3 mr-3 d-none d-sm-flex" v-if="!store.userLogged"></v-divider>
        <!-- <v-btn class="d-none d-sm-flex" link to="/account/new" v-if="!store.userLogged" @click="drawer = false">Criar Conta</v-btn> -->
        <v-btn class="d-none d-sm-flex" :to="{ path: '/account/new', name: 'newAccount' }" v-if="!store.userLogged"
          @click="drawer = !drawer">
          Criar Conta
        </v-btn>

        <v-divider vertical class=" ml-3 mr-3"></v-divider>

        <v-btn link to="/login" v-if="!store.userLogged" @click="drawer = !drawer">Login</v-btn>

        <v-menu class="">
          <template v-slot:activator="{ props }">
            <v-btn v-if="store.userLogged" icon class="px-4" rounded="lg" large v-bind="props">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <v-list density="compact">

            <v-list-item @click="changeMyPassword = true">
              <v-list-item-title>Alterar Senha</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" :location="$vuetify.display.mobile ? 'bottom' : undefined" temporary>

        <v-list density="compact" color="primary" active-class="font-weight-bold" nav>

          <v-list-item to="/" link class="">
            <template v-slot:prepend>
              <v-icon class="">mdi-home</v-icon>
            </template>

            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <!-- menu do usuario logado -->
          <v-divider class="" v-if="store.userLogged"></v-divider>

          <v-list-item to="/myTransactions" link class="" v-if="store.userLogged">
            <template v-slot:prepend>
              <v-icon class="">mdi-bank-transfer</v-icon>
            </template>

            <v-list-item-title>Minhas Transações</v-list-item-title>
          </v-list-item>

          <v-list-item to="/resultsAndProfits" link class="" v-if="store.userLogged && totalTransactions180 > 10">
            <template v-slot:prepend>
              <v-icon class="">mdi-cash-register</v-icon>
            </template>

            <v-list-item-title>Meus Resultados</v-list-item-title>
          </v-list-item>

          <v-divider class="" v-if="store.userLogged"></v-divider>

          <!-- <v-list-group value="Actions" v-if="store.userLogged">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            title="Meus Setups"
          >
            <template v-slot:prepend>
              <v-icon class="">mdi-account-tie</v-icon>
            </template>
          </v-list-item>
        </template>
      
        <v-list-item to="/myCustomSetups" link class="ml-n10" v-if="store.userLogged">
        <template v-slot:prepend>
          <v-icon class="">mdi-chart-areaspline</v-icon>
        </template>
      
        <v-list-item-title>Setups de Teste</v-list-item-title>
      </v-list-item>
      
      
      </v-list-group> -->

          <v-list-item to="/apiManager" link class="" v-if="store.userLogged">
            <template v-slot:prepend>
              <v-icon class="">mdi-key-chain</v-icon>
            </template>

            <v-list-item-title>Chaves de API</v-list-item-title>
          </v-list-item>

          <v-list-item to="/robotConfig" link class="" v-if="store.userLogged">
            <template v-slot:prepend>
              <v-icon class="">mdi-robot-excited-outline</v-icon>
            </template>

            <v-list-item-title>Configurar Robô</v-list-item-title>
          </v-list-item>

          <v-list-group value="Actions" v-if="store.userLogged && store.userLogged.roleAdmin">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" title="Administrador">
                <template v-slot:prepend>
                  <v-icon class="">mdi-account-tie</v-icon>
                </template>
              </v-list-item>
            </template>

            <v-list-item to="/myGlobalConfiguration" link class="" v-if="store.userLogged">
              <template v-slot:prepend>
                <v-icon class="mr-n4">mdi-cogs</v-icon>
              </template>

              <v-list-item-title>Config Robô</v-list-item-title>
            </v-list-item>

            <v-list-item to="/setupResults" link class="" v-if="store.userLogged">
              <template v-slot:prepend>
                <v-icon class="mr-n4">mdi-chart-bar</v-icon>
              </template>

              <v-list-item-title>Setups</v-list-item-title>
            </v-list-item>

            <v-list-item to="/manageCoins" link class="d-none d-sm-flex" v-if="store.userLogged">
              <template v-slot:prepend>
                <v-icon class="mr-n4">mdi-bitcoin</v-icon>
              </template>

              <v-list-item-title>Moedas</v-list-item-title>
            </v-list-item>


          </v-list-group>

          <!-- <v-list-item to="/mySubscriptions" link class="" v-if="store.userLogged">
        <template v-slot:prepend>
          <v-icon class="">mdi-ticket</v-icon>
        </template>
      
        <v-list-item-title>Assinatura</v-list-item-title>
      </v-list-item> -->

          <v-divider class=""></v-divider>

          <v-list-item link to="/faqPage" class="">
            <template v-slot:prepend>
              <v-icon class="">mdi-frequently-asked-questions</v-icon>
            </template>

            <v-list-item-title>Dúvidas</v-list-item-title>
          </v-list-item>

          <v-divider class=""></v-divider>

          <v-list-item to="/removeAccount" link class="" v-if="store.userLogged">
            <template v-slot:prepend>
              <v-icon class="">mdi-delete-forever</v-icon>
            </template>

            <v-list-item-title>Deletar Conta</v-list-item-title>
          </v-list-item>

          <v-divider class=""></v-divider>

          <v-list-item link to="/supportPage" class="">
            <template v-slot:prepend>
              <v-icon class="">mdi-chat-processing-outline</v-icon>
            </template>

            <v-list-item-title>Atendimento</v-list-item-title>
          </v-list-item>

          <v-divider class="" v-if="store.userLogged"></v-divider>

          <v-list-item link to="/privacyTerms">
            <template v-slot:prepend>
              <v-icon class="">mdi-file-document-edit-outline</v-icon>
            </template>

            <v-list-item-title>Política de Privacidade</v-list-item-title>
          </v-list-item>

          <v-list-item @click="doLogout()" class="" v-if="store.userLogged">
            <template v-slot:prepend>
              <v-icon class="">mdi-location-exit</v-icon>
            </template>

            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>

          <v-divider class="" v-if="!store.userLogged"></v-divider>

          <v-list-item to="/account/new" link class="" v-if="!store.userLogged">
            <template v-slot:prepend>
              <v-icon class="">mdi-account-plus-outline</v-icon>
            </template>

            <v-list-item-title>Criar Conta</v-list-item-title>
          </v-list-item>

          <v-list-item to="/login" link class="" v-if="!store.userLogged">
            <template v-slot:prepend>
              <v-icon class="">mdi-login</v-icon>
            </template>

            <v-list-item-title>Login</v-list-item-title>
          </v-list-item>

        </v-list>

      </v-navigation-drawer>

      <v-main>
        <v-container class="" fluid style="">
          <router-view v-slot="{ Component, route }">
            <transition name="slide-x-transition" mode="out-in">
              <component :is="Component" :key="route.path" />
            </transition>
          </router-view>
        </v-container>
      </v-main>
    </v-layout>
  </v-card>


  <!-- dialog de erro -->
  <v-dialog transition="dialog-bottom-transition" width="auto" v-model="store.showDialogError">
    <v-card>
      <v-toolbar color="error" title="Algo deu errado..."></v-toolbar>
      <v-card-text>
        <div class="body-1 pa-6 text-center">Opa, desculpe. Algo de errado aconteceu aqui. <br />
          Pode ter sido um problema na conexão, na rede ou no nosso sistema. <br />
          Aguarde um pouco e tente novamente, ok?
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="store.showDialogError = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <!-- <v-dialog
      transition="dialog-bottom-transition"
      width="450" 
      v-if="store.userLogged"
      v-model="changeMyPassword"
    >
      <v-card :disabled="store.dialogLoader">
        <v-toolbar
          color="primary" class="pr-5"
        >
          <v-toolbar-title>
            Redefinir Senha
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-key-chain-variant</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-text-field class="mb-5" variant="outlined" 
            v-model="newPwd"
            id="newPwd"
            label="Senha"
            maxlength="20"
            :type="show1 ? 'text' : 'password'"
            :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-inner="show1 = !show1"
            counter
            :error-messages="v$.newPwd.$invalid ? ['Digite a senha'] : []">
          </v-text-field>

          <v-text-field class="mb-5" variant="outlined" 
            :disabled="v$.newPwd.$invalid"
            v-model="confirmNewPwd"
            id="confirmNewPwd"
            label="Confirme a Senha"
            maxlength="20"
            :type="show2 ? 'text' : 'password'"
            :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-inner="show2 = !show2"
            counter
            :error-messages="v$.confirmNewPwd.$invalid ? ['Confirme a senha'] : []">
          </v-text-field>

        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn class="px-4" 
            large text flat
            @click="changeMyPassword = false">
            Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn prepend-icon="mdi-check" class="px-4"
            large text color="primary" flat
            :disabled="v$.confirmNewPwd.$invalid"
            @click="updatePwd">
            Atualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

  <!-- loader -->
  <v-dialog v-model="store.dialogLoader" :scrim="false" persistent width="300">
    <v-card color="primary">
      <v-card-text>
        Aguarde...
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog persistent transition="dialog-bottom-transition" v-model="store.dialogRecoverPwd" width="500">
    <v-card :disabled="store.dialogLoader">
      <v-toolbar color="primary" class="pr-4">

        <v-toolbar-title>Recuperar Senha</v-toolbar-title>
        <v-icon>mdi-key-chain-variant</v-icon>
      </v-toolbar>
      <v-card-text class="pa-10">
        <v-text-field class="mb-5" clearable color="primary" v-model="emailRecoverPwd" prepend-icon="mdi-email-outline"
          id="email" label="Email de Cadastro" placeholder="Entre com seu email" maxlength="150"
          @click:clear="errorMessageMail = null"
          :error-messages="v$.emailRecoverPwd.$invalid ? ['Digite um email válido'] : []">
        </v-text-field>
      </v-card-text>
      <v-card-actions class="">
        <v-btn variant="text" color="primary" @click="store.dialogRecoverPwd = false">Fechar</v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="v$.emailRecoverPwd.$invalid" variant="text" color="primary"
          @click="sendRecoverMessage">Enviar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <!-- global snackbar  -->
  <v-snackbar v-model="store.globalSnackbar" close-delay="2000" location="top" class="text-center">
    <div class="text-center">{{ store.globalSnackbarMessage }}</div>
  </v-snackbar>

  <v-row justify="center">
    <v-dialog v-model="store.dialogHelpApi" fullscreen :scrim="false" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Como eu faço?</v-toolbar-title>
          <v-toolbar-items>
            <v-btn @click="store.dialogHelpApi = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="text-justify">
          <div class="text-body-1 mb-6">
            Você precisa apenas configurar isto uma única vez. Os passos são os seguintes:
          </div>
          <div class="text-body-1 mb-6">
            <ol class="px-6">
              <li>
                Acesse sua conta na Binance, pelo computador
              </li>
              <li>
                No menu do usuário (normalmente acima, à direita), selecione "Gerenciamento API"
              </li>
              <li>
                Na tela de API, clique em:<br />
                <span class="font-weight-black">"Criar API"</span>
              </li>
              <li>
                Marque a opção: <br />
                <span class="font-weight-black">"Restringir o acesso apenas a IPs confiáveis"</span>
              </li>
              <li>
                Adicione os nossos 2 IPs: <br />
                <span class="font-weight-black">52.29.96.40 e 52.29.67.170</span>
              </li>
              <li>
                Confirme que estão marcadas as opções: <br />
                <span class="font-weight-black">"Habilitar Leitura" e "Enable Spot & Margin Trading". </span>
              </li>
              <li>
                Estas são as únicas opções necessárias ao Tourmalet Robot.
                <span class="font-weight-black text-error">
                  Não marque as opções de transferência e saque, para sua própria segurança. O robô jamais fará saques
                  ou transferências da sua conta.
                </span>
              </li>
            </ol>
          </div>
          <div class="text-body-1 mb-6">
            Pronto! Depois disso, clique em salvar sua API. Copie os textos de "API Key" e "Secret Key" e coloque aqui
            no seu Robô.
          </div>
        </v-card-text>

      </v-card>
    </v-dialog>
  </v-row>

  <v-dialog transition="dialog-bottom-transition" width="100%" persistent v-if="store.userLogged"
    v-model="changeMyPassword">
    <v-card :disabled="store.dialogLoader">
      <v-toolbar color="primary" class="pr-5">
        <v-toolbar-title>
          Redefinir Senha
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon>mdi-key-chain-variant</v-icon>
      </v-toolbar>
      <v-card-text>
        <v-text-field class="mb-5" variant="outlined" v-model="newPwd" id="newPwd" label="Senha" maxlength="20"
          :type="show1 ? 'text' : 'password'" :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="show1 = !show1" counter :error-messages="v$.newPwd.$invalid ? ['Digite a senha'] : []">
        </v-text-field>

        <v-text-field class="mb-5" variant="outlined" :disabled="v$.newPwd.$invalid" v-model="confirmNewPwd"
          id="confirmNewPwd" label="Confirme a Senha" maxlength="20" :type="show2 ? 'text' : 'password'"
          :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" @click:append-inner="show2 = !show2" counter
          :error-messages="v$.confirmNewPwd.$invalid ? ['Confirme a senha'] : []">
        </v-text-field>

      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn class="px-4" large text flat @click="changeMyPassword = false">
          Cancelar</v-btn>
        <v-spacer></v-spacer>
        <v-btn prepend-icon="mdi-check" class="px-4" large text color="primary" flat
          :disabled="v$.confirmNewPwd.$invalid" @click="updatePwd">
          Atualizar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>


<script>

import router from './router'
import { store } from './store'
import { myMixin } from './mixin';
import services from './ws/services';

import { required, minLength, sameAs, email, maxLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import { aesUtil } from './aes';


export default {
  name: 'App',

  components: {
    
  },

  setup () {
    return { v$: useVuelidate() }
  },

  mixins: [
    myMixin,
  ],

  router,

  beforteMount(){
    
  },

  mounted (){

    document.addEventListener('deviceready', () => {
      store.verbosity = store.INFO; // or store.DEBUG for full debug output

      // Register your products
      store.register({
        type: window.CdvPurchase.PAID_SUBSCRIPTION,
        id: "cripto.robot.tourmalet.month",
        platform: window.CdvPurchase.Platform.APPLE_APPSTORE,
      });

      // Refresh the store to update the product's state
      store.refresh();
    });

    if(!store.userLogged && !store.correntToken){
      console.log('lets log-in!!');

      var storage = window.localStorage;
      let storedUsername = storage.getItem('username');
      
      if(storedUsername && storedUsername.length > 10){
        let storedPassword = storage.getItem('password');

        // this.form.fieldn = aesUtil.decryptString(services.myphrase, storedUsername);
        // this.form.fieldp = aesUtil.decryptString(services.myphrase, storedPassword);
        this.processLogin(aesUtil.decryptString(services.myphrase, storedUsername), aesUtil.decryptString(services.myphrase, storedPassword));
      }
    
    }

    if(store.userLogged){
      this.loadTotalTransactions180();
    }

  },

  data: () => ({

    show1 : false,
    show2 : false,
    
    drawer: true,
    store,

    newPwd : null,
    confirmNewPwd : null,

    emailRecoverPwd : null,
    changeMyPassword : false,

    totalTransactions180 : 0,

  }),

  methods: {

    onDeviceReady() {

      const {store, Platform} = window.plugins.CdvPurchase;
      //refreshUI();
      console.log('cordova purchase: ' + window.plugins.CdvPurchase);
      store.register([{
        type: window.CdvPurchase.PAID_SUBSCRIPTION,
        id: 'my_product',
        platform: Platform.APPLE_APPSTORE,
      }]);
      // store.when()
      //   .productUpdated(refreshUI)
      //   .approved(finishPurchase);
      // store.initialize([Platform.TEST]);
    },

    updatePwd(){
      
      store.dialogLoader = true;

      this.axios({
        method: "post",
        url: services.serviceHost + "/user/atualizasenha",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
        params: {
          id : store.userLogged.id,
          novaSenha : this.newPwd,
        }
      }).then(response => {
        store.userLogged = response.data;
        store.dialogLoader = false;
        store.globalSnackbarMessage = "Senha atualizada"
        store.globalSnackbar = true;
        this.changeMyPassword = false;
        this.reloadUserData();
      })
      .catch(error => {
        console.log(error.message);
        store.dialogLoader = false;
        store.showDialogError = true;
      });
    },

    doLogout(){
      this.logout();
      this.drawer = false;
    },

    loadTotalTransactions180() {

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/robot/transactions/count/180", {}, config)
        .then(response => {
          //console.log(JSON.stringify(response.data));
          this.totalTransactions180 = response.data;
        });
    },
  },

  validations() {
    return {
      newPwd: {
        required,
        min: minLength(6)
      },
      confirmNewPwd: {
        required,
        min: minLength(6),
        sameAs: sameAs(this.newPwd)
      },
      emailRecoverPwd : {
        required, email, 
        min: minLength(6),
        max: maxLength(150),
      },
    }
  }


}



</script>

<style>

app {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.body {
  /* font-family: 'El Messiri', sans-serif; */
  font-family: 'Montserrat', sans-serif;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.8s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0
}

.align-right{
  text-align:right;
}
.align-center{
  text-align:center;
}
.p-datatable th[class*="align-"] .p-column-header-content {
  display: inline-flex ;
}

.switch-center {
  display: flex;
  justify-content: center;
}

.link-no-style {
  text-decoration: none;
  color: inherit;
}

.floating-button {
  position: fixed;
  width: 100%; /* set a width for the div */
  margin: auto; /* set the margin to auto horizontally */
  text-align: center;
  bottom: 16px;
  z-index: 999;
}

.floating-button-top {
  position: fixed;
  width: 100%; /* set a width for the div */
  margin: auto; /* set the margin to auto horizontally */
  text-align: center;
  top: 16px;
  z-index: 999;
}

.no-active.v-btn--active::before {
  opacity: 0;
}

.no-active:focus::before {
  opacity: 0 !important;
}

.responsive-image {
  width: 100%;
  height: auto;
}



</style>