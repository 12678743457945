<template>

<v-row justify="center">
    <v-col cols="12" xl="3" lg="5" md="6" sm="8">

      <v-card :disabled="store.dialogLoader" class="elevation-10">
      <v-toolbar color="primary">

        <v-toolbar-title>Recuperar senha</v-toolbar-title>
        <v-btn icon large link to="/" color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      
      <v-card-text class="">

        <v-text-field v-model="store.recoverPassEmail"
          readonly 
        ></v-text-field>

        <v-expand-transition>
          <div class="mt-5 font-weight-black text-center text-body-1 text-error" v-if="codeGenerateErrorMessageShow">
            O código não pôde ser gerado. O email não existe no nosso sistema ou o email pertence a um usuário inativo. 
          </div>
          <div v-if="codeGeneratedSuccess && !codeValidated">
            <div class="mt-5 font-weight-black text-center text-body-1">
              Código gerado com sucesso. Verifique seu email e digite o código recebido no campo abaixo:
            </div>
            <v-text-field v-model="validationCode" class="mt-4"
              maxlength="11" v-mask="'###-###-###'" clearable
              :error-messages="v$.validationCode.$invalid ? ['Preencha o código'] : []"
            ></v-text-field>
            <div class="mt-5 font-weight-black text-center text-body-1 text-error" v-if="codeValidatedErrorMessageShow">
              O código já expirou ou é inválido.
            </div>
          </div>
        </v-expand-transition>

        <v-expand-transition>
          <div v-if="codeValidated" class="mt-5 mb-5 font-weight-black text-center text-body-1">
            Código validado! Defina sua nova senha:
          </div>
        </v-expand-transition>

        <v-expand-transition>
          <div v-if="codeValidated">
            <v-text-field class="mb-5" counter color="primary"
              v-model="newPwd"
              prepend-icon="mdi-key-outline"
              id="password"
              label="Senha"
              placeholder="digite a senha"
              hint="entre 6 e 15 caracteres"
              maxlength="15"
              :type="show1 ? 'text' : 'password'"
              :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append-inner="show1 = !show1"
              :error-messages="v$.newPwd.$invalid ? ['Digite uma senha válida'] : []">
            </v-text-field>

            <v-text-field class="mb-5" counter color="primary"
              :disabled="v$.newPwd.$invalid"
              v-model="confirmNewPwd"
              prepend-icon="mdi-key-outline"
              id="confirmPassword"
              label="Confirme a senha"
              placeholder="confirme a senha"
              hint="entre 6 e 15 caracteres"
              maxlength="15"
              :type="show2 ? 'text' : 'password'"
              :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append-inner="show2 = !show2"
              :error-messages="v$.confirmNewPwd.$invalid ? ['Confirmação inválida'] : []">
            </v-text-field>
          </div>
        </v-expand-transition>
        <div v-if="passwordUpdated" class="mt-5 mb-5 font-weight-black text-center text-body-1">
          Sua senha foi atualizada, faça login normalmente. 
        </div>

      </v-card-text>

        
      <v-card-actions class="pa-5">
        <v-expand-transition>
          <v-btn variant="flat" color="primary" @click="generateCode" v-if="!codeGeneratedSuccess"
            class="" text :width="isSmallScreen ? '100%' : ''" :disabled="codeGenerateErrorMessageShow">
            Recuperar</v-btn>
        </v-expand-transition>
        <v-expand-transition>
          <v-btn variant="flat" color="primary" @click="vaidateCode" v-if="codeGeneratedSuccess && !codeValidated"
            :disabled="v$.validationCode.$invalid"
            class="" text :width="isSmallScreen ? '100%' : ''">
            Validar Código</v-btn>
        </v-expand-transition>
        <v-expand-transition>
          <v-btn variant="flat" color="primary" @click="saveNewPassword" v-if="codeValidated && !passwordUpdated"
            :disabled="v$.confirmNewPwd.$invalid"
            class="" text :width="isSmallScreen ? '100%' : ''">
            Salvar nova Senha</v-btn>
        </v-expand-transition>
        <v-expand-transition>
          <v-btn variant="flat" color="primary" link to="/login" v-if="passwordUpdated"
            :disabled="v$.confirmNewPwd.$invalid"
            class="" text :width="isSmallScreen ? '100%' : ''">
            Ir para o Login</v-btn>
        </v-expand-transition>
        
      </v-card-actions>
    </v-card>

    </v-col>
  </v-row>


</template>

<script>

import { myMixin } from '../mixin';
import services from '../ws/services';
import { store } from '../store';

import { required, minLength, maxLength, sameAs } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

export default {

  setup () {
      return { v$: useVuelidate() }
  },

  mixins: [
    myMixin,
  ],

  computed : {
    
  },

  data: () => ({

    show1 : false,
    show2 : false,
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    codeGeneratedSuccess : false,
    codeValidated : false,

    codeValidatedErrorMessageShow : false,
    codeGenerateErrorMessageShow : false,

    validationCode: null,
    newPwd : null,
    confirmNewPwd : null,

    passwordUpdated : false,

  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    if(!store.recoverPassEmail){
      this.$router.push("/login");
    }
  },

  methods: {

    saveNewPassword(){

      console.log('saving new password');
      this.passwordUpdated = false;
      store.dialogLoader = true;
      let myData = {
        email : store.recoverPassEmail,
        code : this.validationCode,
        newPwd : this.newPwd,
      };

      this.axios({
        method: "post",
        url: services.serviceHost + "/recover-password/setnewpass",
        headers: {
          "Content-Type": "application/json",
        },
        data : myData
      })
      .then(response => {
        console.log('pass updated status: ' + response.status);
        
        this.passwordUpdated = response.data;
        console.log('pass atualizada validado: ' + this.codeValidated);

        store.dialogLoader = false;
      });

    },

    vaidateCode(){

      this.codeValidatedErrorMessageShow = false;
      store.dialogLoader = true;
      let myData = {
        email : store.recoverPassEmail,
        code : this.validationCode,
      };

      this.axios({
        method: "post",
        url: services.serviceHost + "/recover-password/validatecode",
        headers: {
          "Content-Type": "application/json",
        },
        data : myData
      })
      .then(response => {
        console.log('validate código status: ' + response.status);
        
        this.codeValidated = response.data;
        this.codeValidatedErrorMessageShow = !this.codeValidated;
        console.log('codigo validado: ' + this.codeValidated);

        store.dialogLoader = false;
      });
    },

    generateCode(){

      this.codeGenerateErrorMessageShow = false;
      store.dialogLoader = true;
      let myData = {
        email : store.recoverPassEmail,
      };

      this.axios({
        method: "post",
        url: services.serviceHost + "/recover-password/generatecode",
        headers: {
          "Content-Type": "application/json",
        },
        data : myData
      })
      .then(response => {
        console.log('código status: ' + response.status);
        
        this.codeGeneratedSuccess = response.data;
        this.codeGenerateErrorMessageShow = !this.codeGeneratedSuccess;
        console.log('código gerado: ' + this.codeGeneratedSuccess);

        store.dialogLoader = false;
      });
    },

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  },

  validations() {
    
    return {

      validationCode : {
        required,
        minLength: minLength(11),
        maxLength: maxLength(11),
      },  
      newPwd : {
        required,
        minLength: minLength(6),
        maxLength: maxLength(15),
      },  
      confirmNewPwd : {
        required,
        minLength: minLength(6),
        maxLength: maxLength(15),
        sameAs: sameAs(this.newPwd),
      }, 
    }
  }

}

</script>

<style scoped>


</style>
