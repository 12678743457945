<template>

<v-row class="justify-center">
    <v-col cols="12" md="6" sm="12" class="">
      <v-card :disabled="store.dialogLoader" class="elevation-10">
        <v-toolbar color="primary">

          <v-toolbar-title>Apagar Conta</v-toolbar-title>
          <v-btn icon large link to="/" color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-8 text-body-1 text-justify">
          <span class="font-weight-black">
            <p>A ação de deletar conta é irreversível. Seus dados, estatísticas e operações serão apagadas do nosso sistema. </p>
            <br /><br />
            <p>Caso você tenha operações abertas pelo robô, você mesmo deve conduzir estas operações após deletar sua conta. </p>
            <br /><br />
            <p>Lembre-se de que você tem a opção de apenas desligar seu robô, na tela de configurações.</p>
            <br /><br />
            <p>Para deletar sua conta e todos os seus dados, digite <span class="text-error">"deletar"</span> no campo abaixo e confirme:</p>
          </span>
          <div>
            <v-text-field v-model="textDeleteConfirm" maxlength="7" class="mt-6"
              @keyup="textDeleteConfirm = (textDeleteConfirm ? textDeleteConfirm.replace(' ', '') : '')"
            ></v-text-field>
          </div>
          <v-btn variant="flat" color="red" width="100%" 
            @click="deleteAccount"
            :disabled="!textDeleteConfirm || textDeleteConfirm.length != 7 || textDeleteConfirm.toLowerCase() != 'deletar'">
            Deletar minha conta
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import { myMixin } from '../mixin';
import services from '../ws/services';
import { store } from '../store';

export default {

  mixins: [
    myMixin,
  ],

  computed : {
    
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    textDeleteConfirm : null,
  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    //this.simpleServiceCall();
    //this.loadBasicGlobalData();
  },

  methods: {

    deleteAccount(){

      store.dialogLoader = true;

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/user/deletemyaccount", {}, config)
        .then(response => {
        console.log('conta deletada: ' + response.status);
        store.dialogLoader = false;
        store.globalSnackbarMessage = 'Conta deletada';
        store.globalSnackbar = true;
        this.logout();
      });
    },

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  }

}

</script>

<style scoped>


</style>
