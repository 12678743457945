<template>

  <v-row class="justify-center">
    <v-col cols="12" md="7" sm="12" class="">
      <v-card :disabled="store.dialogLoader" class="elevation-10">
        <v-toolbar color="primary">

          <v-toolbar-title>Dúvidas</v-toolbar-title>
          <v-btn icon large link to="/" color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-8 text-body-1 text-justify">
            <ul class="">
              <li>
                <span class="font-weight-black">
                  Existe risco de perda nas transações?<br /><br />
                </span>
                Sim. Como qualquer transação de investimento, há risco envolvido. As transações do robô fecham tanto no positivo quanto no negativo. 
                Por isso recomendamos que você sempre acompanhe os resultados do robô, e em cenários macroeconômicos desfavoráveis, desligue o robô 
                (pode ser feito facilmente pela tela de configuração).
              </li>
              <li class="mt-6">
                <span class="font-weight-black">
                  Quais são os índices usandos pelo robô nas operações?<br /><br />
                </span>
                O robô opera usando uma combinação de índices conhecidos do mercado financeiro: MACD, RSI, Elder Force Index, médias móveis, bandas de Bollinger, canais de ATR, etc. 
              </li>
              <li class="mt-6">
                <span class="font-weight-black">
                  Poderei cancelar minha conta?<br /><br />
                </span>
                Sim. Seguindo a Lei Geral de Proteção de Dados, oferecemos sempre a opção diretamente no menu para você cancelar e deletar sua conta, a qualquer momento. 
              </li>
              <li class="mt-6">
                <span class="font-weight-black">
                  Posso continuar fazendo minhas próprias trades?<br /><br />
                </span>
                Sim. O Tourmalet não interfere nas suas compras e vendas. Ele controla apenas as transações que ele próprio cria.  
              </li>
            </ul>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import { myMixin } from '../mixin';
import services from '../ws/services';
import { store } from '../store';

export default {

  mixins: [
    myMixin,
  ],

  computed : {
    
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : false,
  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    //this.simpleServiceCall();
    //this.loadBasicGlobalData();
  },

  methods: {

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  }

}

</script>

<style scoped>


</style>
