<template>

  <div>

    <v-expand-transition>
      <v-card :disabled="store.dialogLoader" v-if="listSetupClosedResults" class="elevation-10 mb-4">
        <v-toolbar color="primary">
          <v-toolbar-title>Transações Fechadas</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-10 d-none d-sm-block">
          <v-row class="mb-4">
            <v-col cols="12" md="4" sm="4" class="pt-0 pb-0 font-weight-black">
              Setup
            </v-col>
            <v-col cols="12" md="4" sm="4" class="pt-0 pb-0 font-weight-black">
              Transações
            </v-col>
            <v-col cols="12" md="4" sm="4" class="pt-0 pb-0 font-weight-black">
              Resultado
            </v-col>
          </v-row>
          <v-row v-for="item in listSetupClosedResults" :key="item.methodOfBuy">
            <v-col cols="12" md="4" sm="4" class="pt-0 pb-0">
              {{ item.methodOfBuy }}
            </v-col>
            <v-col cols="12" md="4" sm="4" class="pt-0 pb-0">
              {{ item.transactionCount }}
            </v-col>
            <v-col cols="12" md="4" sm="4" class="pt-0 pb-0">
              {{ formataMoedaUSD(item.transactionResult) }} USDT
            </v-col>
            <v-col cols="12" class="">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pt-10 d-block d-sm-none">
          <v-row v-for="item in listSetupClosedResults" :key="item.methodOfBuy">
            <v-col cols="12" md="4" sm="4" class="pt-0 pb-0 font-weight-black">
              {{ item.methodOfBuy }}
            </v-col>
            <v-col cols="12" md="4" sm="4" class="pt-0 pb-0">
              Transações: {{ item.transactionCount }} 
            </v-col>
            <v-col cols="12" md="4" sm="4" class="pt-0 pb-0">
              Acumulado: {{ formataMoedaUSD(item.transactionResult) }} USDT
            </v-col>
            <v-col cols="12" class="">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-expand-transition>

    <v-expand-transition>
      <v-card :disabled="store.dialogLoader" v-if="listSetupOpenResults" class="elevation-10 mb-4">
        <v-toolbar color="primary">
          <v-toolbar-title>Transações Abertas</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-10 d-none d-sm-block">
          <v-row class="mb-4">
            <v-col cols="12" md="4" sm="4" class="pt-0 pb-0 font-weight-black">
              Setup
            </v-col>
            <v-col cols="12" md="4" sm="4" class="pt-0 pb-0 font-weight-black">
              Transações
            </v-col>
          </v-row>
          <v-row v-for="item in listSetupOpenResults" :key="item.methodOfBuy">
            <v-col cols="12" md="4" sm="4" class="pt-0 pb-0">
              {{ item.methodOfBuy }}
            </v-col>
            <v-col cols="12" md="4" sm="4" class="pt-0 pb-0">
              {{ item.transactionCount }}
            </v-col>
            <v-col cols="12" class="">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pt-10 d-block d-sm-none">
          <v-row v-for="item in listSetupOpenResults" :key="item.methodOfBuy">
            <v-col cols="12" md="4" sm="4" class="pt-0 pb-0 font-weight-black">
              {{ item.methodOfBuy }}
            </v-col>
            <v-col cols="12" md="4" sm="4" class="pt-0 pb-0">
              Transações: {{ item.transactionCount }} 
            </v-col>
            <v-col cols="12" class="">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-expand-transition>


  </div>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

export default {

  mixins: [
    myMixin,
  ],

  computed : {
    
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    listSetupClosedResults : null,
    listSetupOpenResults : null,
  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    this.loadClosedResults();
    this.loadOpenResults(); 
  },

  methods: {

    loadClosedResults(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/robot/transactions-by-setup/close", {}, config)
        .then(response => {
          //console.log(JSON.stringify(response.data));
          this.listSetupClosedResults = response.data.slice().sort((a, b) => b.transactionResult - a.transactionResult);
        });
        
    },

    loadOpenResults(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/robot/transactions-by-setup/open", {}, config)
        .then(response => {
          console.log(JSON.stringify(response.data));
          this.listSetupOpenResults = response.data;
        });
        
    },

  }

}

</script>

<style scoped>


</style>
